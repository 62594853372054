import Axios from 'axios';
import { useMutation, useQueryCache } from 'react-query';

import { useUserContext } from '@/context/user-context';

import { API_URL } from './config';

export const useRegister = (config) => {
  const { updateToken, updateUser } = useUserContext();
  const queryCache = useQueryCache();
  return useMutation(
    ({
      login,
      password,
      firstName,
      lastName,
      phoneNumber,
      areAcceptedAllDocuments,
      areAcceptedDataProcessing,
    }) =>
      Axios.post(`${API_URL}/registrations`, {
        email: login,
        password,
        firstName,
        lastName,
        phoneNumber,
        areAcceptedAllDocuments,
        areAcceptedDataProcessing,
      }),
    {
      ...config,
      onSuccess: (response, ...args) => {
        const { token, ...user } = response || {};
        if (token) {
          queryCache.clear();
          updateUser(user);
          updateToken(token);
          if (config.onSuccess) {
            config.onSuccess(response, ...args);
          }
        }
      },
    }
  );
};
