import { useEffect } from 'react';

import axios from 'axios';
import { useQueryCache, useMutation, useQuery } from 'react-query';

import { useUserContext } from '@/context/user-context';
import { API_URL } from '@/services/config';

export const useLogin = (config) => {
  const { updateToken, updateUser } = useUserContext();
  const queryCache = useQueryCache();
  return useMutation(
    ({ login, password }) =>
      axios.post(`${API_URL}/authenticate`, { email: login, password }),
    {
      ...config,
      onSuccess: (response, ...args) => {
        const { token, ...user } = response;
        queryCache.clear();
        updateUser(user);
        updateToken(token);
        if (config.onSuccess) {
          config.onSuccess(response, ...args);
        }
      },
    }
  );
};

export const useLogout = () => {
  const { updateToken, updateUser } = useUserContext();

  const clearModalLocalStorage = () => {
    localStorage.removeItem('modalAlreadyViewed');
  };

  useEffect(() => {
    clearModalLocalStorage();
    updateToken(null);
    updateUser(null);
  }, [updateToken, updateUser]);
};

export const useInitResetPasswordRequest = (config) =>
  useMutation((values) => axios.post('/reset_password/init', values), config);

export const useChangeResetPasswordRequest = (config) =>
  useMutation((values) => axios.post('/reset_password/change', values), config);

export const useChangeConnectedUserPassword = (config) =>
  useMutation((values) => axios.post('/change_password', values), config);

export const useUpdateAccount = (config) =>
  useMutation((values) => {
    return axios.post('/account', values);
  }, config);

export const useGetAccount = ({ params, ...config } = {}) =>
  useQuery(
    [`user`, params?.email],
    () =>
      axios.get(`/account`, {
        params,
      }),
    { retry: 0, ...config }
  );
