import React from 'react';

import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { isEmail } from '@formiz/validations';
import { FaArrowLeft } from 'react-icons/fa';
import { Redirect, useHistory } from 'react-router-dom';

import { FieldInput, Footer, Link, Logo } from '@/components';
import { BOOKING_REQUEST_STORAGE_KEY } from '@/constants/invoiceRequest';
import { MAIN_STEPS } from '@/constants/steps';
import { useUserContext } from '@/context/user-context';
import { Page } from '@/layout';
import { useLocalStorage } from '@/services/localStorage';
import { useLogin } from '@/services/login';
import { useQueryParam } from '@/services/queryParam';

import { LoginFooter } from './_partials/LoginFooter';

export const Login = () => {
  const { isLogged } = useUserContext();
  const isDesktopScreen = useBreakpointValue({ base: false, md: true });
  const redirect = useQueryParam('redirect');
  const history = useHistory();
  const toast = useToast();
  const loginForm = useForm();
  const bookingRequest = useLocalStorage(BOOKING_REQUEST_STORAGE_KEY, '');

  const goPreviousPath = () => {
    const request = bookingRequest[0];

    if (request !== null) {
      history.push({
        pathname: '/booking-request',
        state: { goToSummaryStep: MAIN_STEPS.SUMMARY },
      });
    } else if (request == null) {
      history.push({
        pathname: '/',
      });
    }
  };

  const [mutate, { isLoading }] = useLogin({
    onError: ({ response }) => {
      if (response?.status === 401) {
        toast({
          title: 'Identifiants incorrects, veuillez ré-essayer',
          status: 'error',
        });
      } else if (response?.status === 503) {
        toast({
          title:
            'Trop de tentatives de connexion, veuillez réessayer dans quelques minutes',
          status: 'error',
        });
      } else {
        toast({
          title: 'Une erreur est survenue lors de la connexion',
          status: 'error',
        });
      }
    },
    onSuccess: () => {
      if (redirect) {
        history.push(redirect);
      }
    },
  });

  const handleSubmit = (values) => {
    mutate(values);
  };

  if (isLogged) {
    return <Redirect to="/" />;
  }

  return (
    <Page px={6} mt={0} mb={{ base: 28, md: 0 }} backgroundColor="gray.50">
      <Box maxW="45em" mx="auto">
        <IconButton
          icon={<FaArrowLeft />}
          color="gray.400"
          variant="ghost"
          onClick={goPreviousPath}
        />
        <Formiz connect={loginForm} onValidSubmit={handleSubmit}>
          <Box as="form" noValidate onSubmit={loginForm.submit} mb={10}>
            <Flex direction="column" align="center" m="auto" maxW="400px">
              <Logo as={Link} mb={{ base: 4, sm: 6 }} width="4rem" to="/" />
              <Heading as="h1" size="lg" mb={6}>
                Connexion
              </Heading>
              <Box w="100%">
                <Text textAlign="center" mb={{ base: 5, sm: 10 }}>
                  Vous devez être connecté pour aller plus loin.{' '}
                </Text>
                <FieldInput
                  name="login"
                  type="email"
                  required="L'adresse email est requise"
                  placeholder="Votre adresse mail"
                  label="Email"
                  validations={[
                    {
                      rule: isEmail(),
                      message: 'Adresse email invalide',
                    },
                  ]}
                />
                <FieldInput
                  name="password"
                  type="password"
                  required="Le mot de passe est requis"
                  placeholder="Votre mot de passe"
                  label={
                    <Flex justifyContent="space-between" width="100%">
                      <Text>Mot de passe</Text>
                      <Button
                        as={Link}
                        variant="link"
                        colorScheme="brandSecondary"
                        to={{
                          pathname: '/forgot-password',
                          search: redirect ? `?redirect=${redirect}` : null,
                        }}
                      >
                        Mot de passe oublié
                      </Button>
                    </Flex>
                  }
                />

                {isDesktopScreen && (
                  <Stack mt={4} spacing={4}>
                    <LoginFooter isLoading={isLoading} />
                  </Stack>
                )}
              </Box>
            </Flex>

            {!isDesktopScreen && (
              <Footer as={Stack} spacing={4} zIndex={3}>
                <LoginFooter isLoading={isLoading} />
              </Footer>
            )}
          </Box>
        </Formiz>
      </Box>
    </Page>
  );
};
