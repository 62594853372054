import React from 'react';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  Button,
  Icon,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FiAlertTriangle } from 'react-icons/fi';

export const ModalForInvalidAccountBookings = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} size="lg" onClose={onClose} closeOnOverlayClick>
      <ModalOverlay>
        <ModalContent bgColor="gray.50">
          <ModalHeader>
            <Flex>
              <Icon as={FiAlertTriangle} w="40px" h="30px" />
              Validez votre compte
            </Flex>
          </ModalHeader>
          <ModalBody>
            La validation de votre compte est nécessaire pour visualiser vos
            demandes, un e-mail vous a été envoyé à cet effet.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="brandSecondary" mr={3} onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

ModalForInvalidAccountBookings.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

ModalForInvalidAccountBookings.defaultProps = {
  isOpen: false,
  onClose: () => {},
};
