import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { BOOKING_REQUEST_SUBJECTS } from '@/constants/bookingRequest';
import {
  HEALTH_APPOINTMENT,
  HEALTH_APPOINTMENT_TYPE,
  HEALTH_MEDICAL_SPECIALIST_TYPE,
  HEALTH_WELL_BEING_AT_WORK_TYPE,
} from '@/constants/health';
import { MEETING_APPOINTMENT_LABEL } from '@/constants/meeting';

import { useSearchByAddress } from './bookingRequest';

export const formatDate = (date) => dayjs(date).format('DD MMM YYYY');

export const getProjectAdvancement = (startDate, endDate) => {
  const currentDate = dayjs();

  const endDateObj = dayjs(endDate);

  const projectDays = endDateObj.diff(startDate, 'day');
  const projectCurrentDays = currentDate.diff(startDate, 'day');

  if (projectCurrentDays >= projectDays) {
    return 100;
  }

  if (projectDays > 0) {
    return Math.round((projectCurrentDays / projectDays) * 100);
  }

  return 0;
};

export const getAppointmentSubjectTextContent = (subject) => ({
  [BOOKING_REQUEST_SUBJECTS.HEALTH.id]:
    HEALTH_MEDICAL_SPECIALIST_TYPE.find(
      (medical) => medical.value === subject?.specialist
    )?.label ||
    HEALTH_WELL_BEING_AT_WORK_TYPE.find(
      (specialist) => specialist.value === subject?.specialist
    )?.label ||
    (HEALTH_APPOINTMENT.GENERAL_PRACTITIONER === subject?.healthType &&
      (HEALTH_APPOINTMENT_TYPE[subject?.specialist]?.name ||
        subject?.specialist)) ||
    HEALTH_APPOINTMENT_TYPE[subject?.healthType]?.name ||
    '',
  [BOOKING_REQUEST_SUBJECTS.MEETING.id]:
    MEETING_APPOINTMENT_LABEL[subject?.meetingCategory] +
    (subject?.meetingType ? ` - ${subject?.meetingType}` : ''),

  [BOOKING_REQUEST_SUBJECTS.JUSTICE.id]:
    MEETING_APPOINTMENT_LABEL[subject?.justiceCategory] +
    (subject?.professionalType ? ` - ${subject?.professionalType}` : ''),

  [BOOKING_REQUEST_SUBJECTS.EVENT.id]: subject?.eventType
    ? subject?.eventType
    : '',
  [BOOKING_REQUEST_SUBJECTS.OTHER.id]: subject?.subject,
});

// We can use this hook with react query call search call
export const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useCompleteSearchAddress = ({ enabled = true } = {}) => {
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchText, setSearchText] = useState('');

  const debouncedSearchQuery = useDebounce(searchText, 600);
  const { isLoading: isOptionLoading } = useSearchByAddress({
    params: {
      search: debouncedSearchQuery,
    },
    enabled: !!debouncedSearchQuery && !!searchText && enabled,
    onSuccess: (response) => {
      const features = response?.features || [];
      const results = (features || []).map((feature) => {
        return {
          label: feature?.properties?.label,
          city: feature?.properties?.locality,
          streetAddress: feature?.properties?.street,
          name: feature?.properties?.name,
          zipCode: feature?.properties?.postalcode,
          value: feature?.properties?.label,
          apiData: feature,
        };
      });
      setSearchOptions([...results]);
    },
  });

  const handleInputChange = (value, action) => {
    /** don't clear the input when we blur the input field */
    if (
      action.action !== 'input-blur' &&
      action.action !== 'menu-close' &&
      action.action !== 'set-value'
    ) {
      setSearchText(value);
    }
    if (action.action === 'set-value') {
      setSearchText('');
    }
  };

  return {
    handleInputChange,
    searchOptions,
    setSearchOptions,
    setSearchText,
    searchText,
    isOptionLoading,
  };
};

export const useChunkyUploadFile = ({ mutateUpload, onError = () => {} }) => {
  const [isLoading, setIsLoading] = useState(false);
  const uploadChunks = async ({ chunksList, fileId }) => {
    let isLastPart = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const chunkPart of chunksList) {
      if (chunkPart === chunksList[chunksList.length - 1]) {
        isLastPart = true;
      }
      const formData2 = new FormData();
      Object.keys(chunkPart).map((ch) => formData2.append(ch, chunkPart[ch]));
      formData2.append('uuid', fileId);
      // eslint-disable-next-line no-await-in-loop
      const res = await mutateUpload({ formData: formData2, isLastPart });
      if (!res) {
        return false;
      }
    }
    return true;
  };

  const handleUploadFile = async (file) => {
    if (file) {
      const chunkSize = (1024 * 1024) / 4;
      const fileSize = file.size;
      const chunks = Math.ceil(file.size / chunkSize, chunkSize);
      let chunk = 0;
      let chunkFile = null;
      const chunksList = [];
      setIsLoading(true);

      while (chunk < chunks) {
        const offset = chunk * chunkSize;
        chunkFile = file.slice(offset, offset + chunkSize);
        chunksList.push({
          'chunk-id': chunk,
          chunk: chunkFile,
          'file-size': fileSize,
          'file-name': file.name,
          'file-total-chunks': chunks,
        });
        chunk += 1;
      }
      if (chunksList?.length) {
        const formData = new FormData();
        Object.keys(chunksList[0]).map((ch) =>
          formData.append(ch, chunksList[0][ch])
        );
        let isSuccess = true;
        const { uuid: fileId } = await mutateUpload({ formData, chunksList });

        if (fileId) {
          isSuccess = await uploadChunks({ chunksList, fileId });
        } else {
          isSuccess = false;
        }
        if (!isSuccess) {
          onError();
        }
      }
    }
    setIsLoading(false);
  };

  return { handleUploadFile, isLoading };
};
