import React from 'react';

import { Flex, Spacer, Stack } from '@chakra-ui/react';
import { FormizStep, useForm } from '@formiz/core';

import { FieldInput, FieldRadioGroup, FieldSelect } from '@/components';
import {
  BOOKING_REQUEST_CATEGORY,
  BOOKING_REQUEST_SUBJECTS,
} from '@/constants/bookingRequest';
import {
  HEALTH_APPOINTMENT,
  HEALTH_APPOINTMENT_TYPE,
  HEALTH_BY_CATEGORY,
  HEALTH_MEDICAL_SPECIALIST_TYPE,
  HEALTH_WELL_BEING_AT_WORK_TYPE,
} from '@/constants/health';
import { FORM_STEPS } from '@/constants/steps';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';
import { RadioItem } from '@/pages/BookingRequest/_partials/RadioItem';

const {
  GENERAL_PRACTITIONER,
  MEDICAL_SPECIALIST,
  OCCUPATIONAL_MEDICINE,
  WELL_BEING_AT_WORK,
} = HEALTH_APPOINTMENT;

const appointmentTypes = {
  [BOOKING_REQUEST_CATEGORY.PERSONAL.id]: [
    GENERAL_PRACTITIONER,
    MEDICAL_SPECIALIST,
  ],
  [BOOKING_REQUEST_CATEGORY.PROFESSIONAL.id]: [
    OCCUPATIONAL_MEDICINE,
    WELL_BEING_AT_WORK,
  ],
};

const specialistTypes = {
  [MEDICAL_SPECIALIST]: HEALTH_MEDICAL_SPECIALIST_TYPE,
  [WELL_BEING_AT_WORK]: HEALTH_WELL_BEING_AT_WORK_TYPE,
};

export const Health = () => {
  const form = useForm({
    subscribe: {
      form: false,
      fields: true,
    },
  });
  const { values } = form;
  const { appointment } = values;
  const { category, health } = appointment || { category: null, health: null };

  return (
    <>
      <FormizStep
        name={FORM_STEPS.HEALTH_SUBJECT.TYPE.name}
        order={FORM_STEPS.HEALTH_SUBJECT.TYPE.order}
      >
        <Flex>
          <AppointmentHeading
            title={HEALTH_BY_CATEGORY[category].heading}
            helper="Quel est le type de soin ?"
          />
          <Spacer />
        </Flex>
        <FieldRadioGroup
          id="appointment-health-type"
          name={`appointment.${BOOKING_REQUEST_SUBJECTS.HEALTH.id}.type`}
          required="Vous devez choisir un type de rendez-vous"
          mt={4}
        >
          <Stack>
            {(appointmentTypes[category] || []).map((type) => (
              <RadioItem
                key={type}
                {...HEALTH_APPOINTMENT_TYPE[type]}
                id={type}
              />
            ))}
          </Stack>
        </FieldRadioGroup>
      </FormizStep>
      {[MEDICAL_SPECIALIST, WELL_BEING_AT_WORK, GENERAL_PRACTITIONER].includes(
        health?.type
      ) && (
        <FormizStep
          name={FORM_STEPS.HEALTH_SUBJECT.SPECIALIST_TYPE.name}
          order={FORM_STEPS.HEALTH_SUBJECT.SPECIALIST_TYPE.order}
        >
          <Flex>
            <Spacer />
          </Flex>
          {[
            HEALTH_APPOINTMENT.GENERAL_PRACTITIONER,
            HEALTH_APPOINTMENT.WELL_BEING_AT_WORK,
          ].includes(
            appointment?.[BOOKING_REQUEST_SUBJECTS.HEALTH.id]?.type
          ) && (
            <FieldInput
              id="appointment-health-specialist-type"
              name={`appointment.${BOOKING_REQUEST_SUBJECTS.HEALTH.id}.specialist`}
              label={HEALTH_BY_CATEGORY[category].serviceType}
              helper={HEALTH_BY_CATEGORY[category].serviceTypeHelper}
              placeholder={HEALTH_BY_CATEGORY[category].serviceTypePlaceholder}
              required="Vous devez entrer un service"
            />
          )}
          {![
            HEALTH_APPOINTMENT.GENERAL_PRACTITIONER,
            HEALTH_APPOINTMENT.WELL_BEING_AT_WORK,
          ].includes(
            appointment?.[BOOKING_REQUEST_SUBJECTS.HEALTH.id]?.type
          ) && (
            <FieldSelect
              id="appointment-health-specialist-type"
              name={`appointment.${BOOKING_REQUEST_SUBJECTS.HEALTH.id}.specialist`}
              label={HEALTH_BY_CATEGORY[category].serviceType}
              helper={HEALTH_BY_CATEGORY[category].serviceTypeHelper}
              placeholder={HEALTH_BY_CATEGORY[category].serviceTypePlaceholder}
              formatCreateLabel={(input) => `Autre... : "${input}"`}
              required="Vous devez choisir un service"
              options={specialistTypes[health?.type] || []}
            />
          )}
        </FormizStep>
      )}
    </>
  );
};
