import React from 'react';

import { Box } from '@chakra-ui/react';
import { FormizStep, useForm } from '@formiz/core';
import { isNotEmptyString, isPattern } from '@formiz/validations';
import { FaMapMarker } from 'react-icons/fa';

import { FieldCheckbox, FieldInput, FieldSelect } from '@/components';
import { BOOKING_REQUEST_CATEGORY } from '@/constants/bookingRequest';
import {
  BOOKING_REQUEST_STORAGE_KEY,
  INVOICE_REQUEST_PAYERS,
  INVOICE_REQUEST_STEPS,
} from '@/constants/invoiceRequest';
import { useLocalStorage } from '@/services/localStorage';
import { useGetAccount } from '@/services/login';
import { useCompleteSearchAddress } from '@/services/utils';
import {
  SIRET_PATTERN_VALIDATION,
  formatInternationalPhoneNumber,
  isValidFrenchPhoneNumber,
} from '@/utils/formCustomValidations';

import { InvoiceRequestHeading } from '../_partials/InvoiceRequestHeading';

export const PaymentInformationForm = () => {
  const [bookingRequest] = useLocalStorage(BOOKING_REQUEST_STORAGE_KEY, '');
  const {
    values: { payer },
    currentStep,
  } = useForm({ subscribe: { form: true, fields: ['payer'] } });
  const {
    searchOptions,
    setSearchText,
    searchText,
    isOptionLoading,
  } = useCompleteSearchAddress({
    enabled:
      INVOICE_REQUEST_STEPS.PAYMENT_INFORMATION.name === currentStep?.name,
  });
  const isInvoiceRequestCompanyPayer =
    payer === INVOICE_REQUEST_PAYERS.COMPANY &&
    bookingRequest?.appointment?.category ===
      BOOKING_REQUEST_CATEGORY.PROFESSIONAL.id;

  const getAccount = useGetAccount();
  const phoneNumber = getAccount?.data?.phoneNumber;

  return (
    <FormizStep
      name={INVOICE_REQUEST_STEPS.PAYMENT_INFORMATION.name}
      order={INVOICE_REQUEST_STEPS.PAYMENT_INFORMATION.order}
    >
      <InvoiceRequestHeading
        title="Informations supplémentaires"
        helper={
          isInvoiceRequestCompanyPayer
            ? 'Quelle est l’adresse à inscrire sur le devis et la facture ?'
            : 'Les informations ci-dessous serviront à établir un devis adapté.'
        }
        mb={4}
      />
      {isInvoiceRequestCompanyPayer && (
        <>
          <FieldInput
            name="companyName"
            label="Organisme"
            required="La raison sociale est requise"
            validations={[
              {
                rule: isNotEmptyString(),
                message: 'La raison sociale est requise',
              },
            ]}
            helper=" Ex : Nom de l’entreprise, de l’association, de l’établissement public, …"
            h="6.5rem"
          />
          <FieldInput
            name="siret"
            label="Numéro de SIRET"
            validations={[
              {
                rule: isPattern(SIRET_PATTERN_VALIDATION),
                message: 'Le numéro SIRET doit avoir 14 chiffres',
              },
            ]}
            h="6.5rem"
          />
        </>
      )}
      <FieldSelect
        name="invoiceAddress"
        label={
          isInvoiceRequestCompanyPayer ? (
            <span>
              Adresse<span style={{ color: 'red' }}> *</span>
            </span>
          ) : (
            <span>
              Adresse de facturation<span style={{ color: 'red' }}> *</span>
            </span>
          )
        }
        helper="Quelle est l’adresse à inscrire sur le devis et la facture ?"
        placeholder="Saisissez une adresse"
        required="Vous devez entrer une adresse"
        mt={20}
        validations={[
          {
            rule: (val) =>
              !val || (val?.zipCode && val?.streetAddress && val?.city),
            message: 'Vous devez saisir une adresse complète',
          },
        ]}
        options={searchOptions}
        onInputChange={(e) => setSearchText(e || '')}
        icon={FaMapMarker}
        noOptionsMessage={
          isOptionLoading
            ? 'Recherche en cours...'
            : 'Aucune adresse disponible'
        }
        selectProps={{
          isClearable: true,
          inputValue: searchText,
          filterOption: () => true,
        }}
      />
      {(payer === INVOICE_REQUEST_PAYERS.YOU || !payer) && (
        <FieldCheckbox
          name="useAsDefaultInvoiceAddress"
          label="Utiliser cette adresse pour mes prochaines demandes"
          checkboxProps={{
            float: 'right',
          }}
        />
      )}
      {phoneNumber ? (
        <FieldInput
          name="phoneNumber"
          label="Téléphone"
          formatValue={formatInternationalPhoneNumber}
          defaultValue={phoneNumber}
          key={phoneNumber}
          mt={20}
          validations={[
            {
              rule: isValidFrenchPhoneNumber(),
              message: "Votre numéro de téléphone n'est pas valide",
            },
          ]}
        />
      ) : (
        <FieldInput
          name="phoneNumber"
          label="Téléphone"
          placeholder="Votre numéro de téléphone"
          formatValue={formatInternationalPhoneNumber}
          mt={20}
          validations={[
            {
              rule: isValidFrenchPhoneNumber(),
              message: "Votre numéro de téléphone n'est pas valide",
            },
          ]}
        />
      )}
      <Box marginTop={20}>
        <span style={{ color: 'red' }}> *</span> Mentions obligatoires
      </Box>
    </FormizStep>
  );
};
