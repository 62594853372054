import React from 'react';

import { Box, Flex, forwardRef, Heading, Spacer } from '@chakra-ui/react';
import { useForm } from '@formiz/core';
import dayjs from 'dayjs';
import * as PropTypes from 'prop-types';

import { FieldDayPicker, FieldInput, DeleteButton } from '@/components';
import { DATE_SELECTION_TAGS } from '@/constants/date';
import {
  isStartTimeBeforeEndTime,
  isTimeBeforeNow,
} from '@/utils/formCustomValidations';

export const hasDateOverlap = (appointments = []) => {
  const mappedAppointments = appointments?.map((date) => {
    let startDate = dayjs(date.date).format('YYYY-MM-DD');
    if (date.startTime) {
      const [hours, minutes] = date.startTime.split(':');
      startDate = dayjs(`${startDate} ${hours}:${minutes}`, 'YYYY-MM-DD HH:mm');
    }
    let endDate = dayjs(date.date).format('YYYY-MM-DD');
    if (date.endTime) {
      const [hours, minutes] = date.endTime.split(':');
      endDate = dayjs(`${endDate} ${hours}:${minutes}`, 'YYYY-MM-DD HH:mm');
    }
    return {
      startDate,
      endDate,
    };
  });

  if (mappedAppointments?.length <= 1) {
    return true;
  }

  const overlaps = mappedAppointments.some((item, index) => {
    return mappedAppointments.some((item2, index2) => {
      if (index === index2) {
        return false;
      }
      return (
        item.startDate.isSame(item2.startDate) &&
        item.endDate.isSame(item2.endDate)
      );
    });
  });
  return !overlaps;
};

export const AppointmentFreeDateRepeaterForm = forwardRef(
  ({ index, deleteButtonProps }, ref) => {
    const { values } = useForm({
      subscribe: {
        fields: [
          `appointment.datetime.dates[${index}].date`,
          `appointment.datetime.dates[${index}].startTime`,
          `appointment.datetime.dates[${index}].endTime`,
        ],
      },
    });
    const date = values?.appointment?.datetime?.dates
      ? values?.appointment?.datetime.dates[index]?.date
      : '';
    const startTime = values?.appointment?.datetime?.dates
      ? values?.appointment?.datetime?.dates[index]?.startTime
      : '';

    return (
      <Box
        mt="4"
        backgroundColor="gray.50"
        border="1px dashed"
        borderColor="gray.200"
        p="3"
        pb="5"
        ref={ref}
      >
        <Flex direction="row">
          <Heading as="h4" size="lg" pt={2}>
            {DATE_SELECTION_TAGS[index]}
            {` `}possible
          </Heading>
          <Spacer />
          <DeleteButton {...deleteButtonProps} />
        </Flex>
        <>
          <FieldDayPicker
            name={`appointment.datetime.dates[${index}].date`}
            label="Date"
            placeholder=".. /.. / ...."
            required="La date de rendez-vous est requise"
          />
          <Flex align="center" direction={{ base: 'column', md: 'row' }} pt="4">
            <FieldInput
              name={`appointment.datetime.dates[${index}].startTime`}
              label="Heure de début du rendez-vous"
              type="time"
              mr={{ base: 0, md: 2 }}
              alignSelf={{ base: undefined, md: 'flex-start' }}
              required="L'heure de début est requise"
              validations={[
                {
                  rule: (val) =>
                    !date ||
                    !startTime ||
                    (date &&
                      isTimeBeforeNow({
                        date,
                        time: val,
                      })),
                  message:
                    "Le rendez-vous doit être pris au moins deux heure à l'avance",
                  deps: [date],
                },
              ]}
            />
            <FieldInput
              label="Heure de fin du rendez-vous"
              type="time"
              name={`appointment.datetime.dates[${index}].endTime`}
              required="L'heure de fin est requise"
              alignSelf={{ base: undefined, md: 'flex-start' }}
              mt={{ base: 4, md: 0 }}
              validations={[
                {
                  rule: (val) =>
                    !startTime ||
                    isStartTimeBeforeEndTime({
                      startTime,
                      endTime: val,
                    }),
                  message: "L'heure de fin est avant l'heure de début",
                  deps: [startTime],
                },
              ]}
            />
          </Flex>
        </>
      </Box>
    );
  }
);

AppointmentFreeDateRepeaterForm.propTypes = {
  index: PropTypes.number.isRequired,
  deleteButtonProps: PropTypes.shape({
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
  }),
};

AppointmentFreeDateRepeaterForm.defaultProps = {
  deleteButtonProps: {
    onClick: () => {},
    isDisabled: false,
  },
};
