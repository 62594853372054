import React from 'react';

import { Box, Button, Flex, Heading, Stack, useToast } from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { isMinLength, isPattern } from '@formiz/validations';

import { FieldInput, Footer } from '@/components';
import { Page, AppHeader } from '@/layout';
import { useChangeConnectedUserPassword } from '@/services/login';
import {
  HAS_DIGIT,
  HAS_LOWCASE_CHARACTER,
  HAS_UPPERCASE_CHARACTER,
} from '@/utils/formCustomValidations';

export const Password = () => {
  const form = useForm();
  const toast = useToast();

  const [updatePassword, { isLoading }] = useChangeConnectedUserPassword({
    onError: ({ response }) => {
      if (response?.status === 400) {
        toast({
          title: response?.data
            ? `${response?.data}`
            : "Le mot de passe n'est pas assez sécurisé",
          status: 'error',
        });
      } else if (response?.status === 401) {
        toast({
          title: response?.data
            ? `${response?.data}`
            : "L'ancien mot de passe saisi n'est pas correct",
          status: 'error',
        });
      } else if (response?.status === 503) {
        toast({
          title:
            'Trop de tentatives de modification du mot de passe, veuillez réessayer dans quelques minutes',
          status: 'error',
        });
      } else {
        toast({
          title:
            'Une erreur est survenue lors de la modification du mot de passe',
          status: 'error',
        });
      }
    },
    onSuccess: () => {
      toast({
        title: 'Le mot de passe a été modifié avec succès',
        status: 'success',
      });
    },
  });

  const handleValidSubmit = (values) => {
    const passwordData = {
      oldPassword: values?.oldPassword,
      newPassword: values?.password,
    };

    updatePassword(passwordData);
  };

  return (
    <Page px={6} pb={4} pt={16}>
      <AppHeader title="résa'pi" />
      <Formiz connect={form} onValidSubmit={handleValidSubmit}>
        <Box as="form" noValidate onSubmit={form.submit}>
          <Flex direction="column" align="center" m="auto" maxW="400px">
            <Heading as="h2" size="lg" mb={10}>
              Changer le mot de passe
            </Heading>
            <Box w="100%">
              <FieldInput
                name="oldPassword"
                type="password"
                required="Votre ancien mot de passe est requis"
                label={
                  <span>
                    L&apos;ancien mot de passe
                    <span style={{ color: 'red' }}> *</span>
                  </span>
                }
                mt={10}
                validations={[
                  {
                    rule: isMinLength(8),
                    message: 'Le mot de passe doit avoir au moins 8 caractères',
                  },
                ]}
              />
              <FieldInput
                name="password"
                type="password"
                required="Le mot de passe est requis"
                label={
                  <span>
                    Nouveau mot de passe<span style={{ color: 'red' }}> *</span>
                  </span>
                }
                mt={10}
                validations={[
                  {
                    rule: isMinLength(8),
                    message: 'Le mot de passe doit avoir au moins 8 caractères',
                  },
                  {
                    rule: (value) =>
                      isPattern(HAS_DIGIT)(value) ||
                      (isPattern(HAS_LOWCASE_CHARACTER)(value) &&
                        isPattern(HAS_UPPERCASE_CHARACTER)(value)),
                    message:
                      'Le mot de passe doit contenir soit un mélange de majuscules' +
                      ' et de minuscule, soit au moins un chiffre',
                    deps: [form.values.password],
                  },
                ]}
              />
              <FieldInput
                name="confirmPassword"
                type="password"
                required="La confirmation du mot de passe est requise"
                label={
                  <span>
                    Confirmation du mot de passe
                    <span style={{ color: 'red' }}> *</span>
                  </span>
                }
                mt={10}
                validations={[
                  {
                    rule: (value) => form.values.password === value,
                    message: 'Le mot de passe ne correspond pas',
                    deps: [form.values.password],
                  },
                ]}
              />
              <Box marginTop={4}>
                <span style={{ color: 'red' }}> *</span> Mentions obligatoires
              </Box>

              <Stack spacing={4} d={{ base: 'none', md: 'flex' }} mt={6}>
                <Button
                  type="submit"
                  colorScheme="brandSecondary"
                  isDisabled={isLoading}
                >
                  Envoyer
                </Button>
              </Stack>
            </Box>
          </Flex>

          <Footer
            as={Stack}
            spacing={4}
            d={{ base: 'flex', md: 'none' }}
            alignItems="center"
          >
            <Button
              type="submit"
              w="70%"
              colorScheme="brandSecondary"
              isDisabled={isLoading}
            >
              Envoyer
            </Button>
          </Footer>
        </Box>
      </Formiz>
    </Page>
  );
};
