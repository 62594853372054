import React from 'react';

import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { PropTypes } from 'prop-types';
import {
  FaCheckCircle,
  FaTimes,
  FaExclamationTriangle,
  FaPlayCircle,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Help } from '@/assets/icons/Help';
import {
  Footer,
  Header,
  HeaderRightAction,
  HeaderTitle,
  Icon,
  PreviewModal,
} from '@/components';
import { Page } from '@/layout';

import { InvoiceRequestHeading } from './InvoiceRequestHeading';

export const InvoiceRequestSent = ({ bookingId, isActivationNeeded }) => {
  const titleOfNonActivateAccount = 'Demande en attente';
  const titleOfActivateAccount = 'Demande envoyée';
  const helperOfInvoiceRequestHeadingForNonActiveAccount =
    'Merci de consulter votre messagerie pour valider votre compte. Votre demande sera envoyée automatiquement une fois votre compte validé.';
  const helperOfInvoiceRequestHeading =
    'Elle sera traitée sous 48 heures environ. Retrouvez toutes les informations dans la liste de vos demandes.';
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Page d="flex" flexGrow={1} bg="gray.100">
      <Header position="fixed" top="0" left="0" right="0">
        <HeaderTitle>Demander un devis</HeaderTitle>
        <Box position="relative" w="100%" maxWidth="45em" h="100%">
          <HeaderRightAction right={0}>
            <IconButton
              as={Link}
              to="/"
              colorScheme="gray"
              color="gray.400"
              variant="ghost"
              icon={<FaTimes />}
            />
          </HeaderRightAction>
        </Box>
      </Header>

      <Center flexDirection="column" justifyContent="flex-start" flex={1}>
        <Icon
          icon={isActivationNeeded ? FaExclamationTriangle : FaCheckCircle}
          color={isActivationNeeded ? '#EA9900' : 'brandSecondary.400'}
          fontSize="5em"
          mt={10}
        />
        <InvoiceRequestHeading
          title={
            isActivationNeeded
              ? titleOfNonActivateAccount
              : titleOfActivateAccount
          }
          helper={
            isActivationNeeded
              ? helperOfInvoiceRequestHeadingForNonActiveAccount
              : helperOfInvoiceRequestHeading
          }
          textAlign="center"
          mt={6}
        />
        <Button
          leftIcon={<Icon icon={Help} fontSize="3rem" w="2rem" />}
          rightIcon={<Icon icon={FaPlayCircle} color="white" fontSize="30px" />}
          top={{ base: '4.5rem' }}
          w="5rem"
          bg="brandSecondary.600"
          iconSpacing={0}
          borderRadius="25px"
          _hover={{
            bg: 'brandSecondary.700',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
          _active={{
            bg: 'brandSecondary.700',
          }}
          mx={4}
          my={2}
          onClick={onOpen}
        />
      </Center>

      <Footer h={16}>
        <Flex
          justifyContent="flex-end"
          flexGrow={1}
          marginX="auto"
          width={{ base: '100%', md: 'auto' }}
        >
          <Button
            as={Link}
            to={`/bookings/${bookingId}`}
            colorScheme="brandSecondary"
            paddingLeft={{ base: '6rem' }}
            paddingRight={{ base: '6rem' }}
            mr="50%"
            transform="translate(50%)"
          >
            Voir ma demande
          </Button>
        </Flex>
        <PreviewModal
          onClose={onClose}
          isOpen={isOpen}
          url="videos/gameAlmostOver.mp4"
        />
      </Footer>
    </Page>
  );
};
InvoiceRequestSent.propTypes = {
  bookingId: PropTypes.string,
  isActivationNeeded: PropTypes.bool,
};

InvoiceRequestSent.defaultProps = {
  bookingId: '',
  isActivationNeeded: false,
};
