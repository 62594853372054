import React from 'react';

import { Button, Flex } from '@chakra-ui/react';
import { useForm } from '@formiz/core';
import PropTypes from 'prop-types';

import { Footer } from '@/components';
import { FORM_STEPS } from '@/constants/steps';

export const BookingRequestFooter = ({
  isDisabledNextButton,
  appointment,
  setAbleToHistoryPush,
  ...otherProps
}) => {
  const form = useForm({ subscribe: 'form' });
  const { isFirstStep, prevStep } = useForm({ subscribe: 'form' });

  const handleStepWhenModification = () => {
    return form?.steps?.map((step) => {
      if (step?.name === 'SUMMARY' && step?.isVisited) {
        if (
          (form?.currentStep?.name === 'APPOINTMENT_LOCATION' ||
            form?.currentStep?.name ===
              'APPOINTMENT_INFORMATION_MEETING_FACE_TO_FACE' ||
            form?.currentStep?.name ===
              'APPOINTMENT_INFORMATION_HEALTH_SUBJECT_SPECIALIST_TYPE' ||
            form?.currentStep?.name ===
              'APPOINTMENT_INFORMATION_EVENT_SPECIFICS' ||
            form?.currentStep?.name ===
              'APPOINTMENT_INFORMATION_JUSTICE_INFORMATION') &&
          form?.currentStep?.isValid === true
        ) {
          form.goToStep(FORM_STEPS.SUMMARY.name);
          setAbleToHistoryPush(true);
        } else if (
          form?.currentStep?.name ===
          'APPOINTMENT_INFORMATION_HEALTH_SUBJECT_TYPE'
        ) {
          return appointment?.health?.type === 'occupational_medicine'
            ? (form.goToStep(FORM_STEPS.SUMMARY.name),
              setAbleToHistoryPush(true))
            : form.goToStep(FORM_STEPS.HEALTH_SUBJECT.SPECIALIST_TYPE);
        }
      }
      return step;
    });
  };

  return (
    <Footer h={16} {...otherProps}>
      <Flex
        justifyContent="space-between"
        flexGrow={1}
        marginX="auto"
        maxWidth="45em"
      >
        <Button
          onClick={prevStep}
          width={{ base: '100%', md: 'auto' }}
          colorScheme="gray"
          isDisabled={isFirstStep}
        >
          Précédent
        </Button>
        <Button
          type="submit"
          width={{ base: '100%', md: 'auto' }}
          colorScheme="brandSecondary"
          onClick={handleStepWhenModification}
          isDisabled={
            (form.isStepSubmitted && !form.currentStep.isValid) ||
            isDisabledNextButton
          }
        >
          Suivant
        </Button>
      </Flex>
    </Footer>
  );
};

BookingRequestFooter.propTypes = {
  isDisabledNextButton: PropTypes.bool,
  appointment: PropTypes.objectOf(PropTypes.any),
  setAbleToHistoryPush: PropTypes.bool,
};

BookingRequestFooter.defaultProps = {
  isDisabledNextButton: false,
  appointment: {},
  setAbleToHistoryPush: false,
};
