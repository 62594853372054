import React from 'react';

import { Box, Flex, Spacer } from '@chakra-ui/react';
import { useForm } from '@formiz/core';

import { FieldDayPicker, FieldInput } from '@/components';
import { BOOKING_REQUEST_DATE_TIME } from '@/constants/bookingRequest';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';
import {
  isStartTimeBeforeEndTime,
  isTimeBeforeNow,
} from '@/utils/formCustomValidations';

export const AppointmentDateTimeInformation = () => {
  const { values } = useForm({
    subscribe: {
      fields: [
        'appointment.datetime.dates[0].date',
        'appointment.datetime.dates[0].startTime',
        'appointment.datetime.dates[0].endTime',
        'appointment.datetime.appointmentDateTimeType',
      ],
    },
  });
  let date = null;
  const dateType =
    !!values.appointment.datetime.appointmentDateTimeType &&
    values.appointment.datetime.appointmentDateTimeType ===
      BOOKING_REQUEST_DATE_TIME.RECURRING_DATE.id
      ? `Date récurrente`
      : `Date fixe du rendez-vous` || '';
  let startTime = null;
  if (values?.appointment?.datetime?.dates) {
    date = values?.appointment?.datetime?.dates[0]?.date;
    startTime = values?.appointment?.datetime?.dates[0]?.startTime;
  }

  return (
    <>
      <Flex>
        <AppointmentHeading
          title={dateType}
          helper="Choisissez le type de date en fonction des caractéristiques du
      rendez-vous."
        />
        <Spacer />
      </Flex>
      <Box mt="4">
        <FieldDayPicker
          name="appointment.datetime.dates[0].date"
          label={
            dateType !== 'Date fixe du rendez-vous'
              ? `Date du rendez-vous`
              : `Date`
          }
          placeholder=".. /.. / ...."
          required="La date de rendez-vous est requise"
          mb={6}
          h="5rem"
        />
        <Flex direction={{ base: 'column', md: 'row' }}>
          <FieldInput
            name="appointment.datetime.dates[0].startTime"
            label="Heure de début du rendez-vous"
            type="time"
            alignSelf={{ base: undefined, md: 'flex-start' }}
            required="L'heure de début de rendez-vous est requise"
            mr={{ base: 0, md: 2 }}
            validations={[
              {
                rule: (val) =>
                  !date ||
                  !startTime ||
                  (date &&
                    isTimeBeforeNow({
                      date,
                      time: val,
                    })),
                message:
                  "Le rendez-vous doit être pris au moins deux heure à l'avance",
                deps: [date],
              },
            ]}
          />
          <FieldInput
            name="appointment.datetime.dates[0].endTime"
            label="Heure de fin du rendez-vous"
            type="time"
            required="L'heure de fin de rendez-vous est requise"
            alignSelf={{ base: undefined, md: 'flex-start' }}
            mt={{ base: 4, md: 0 }}
            validations={[
              {
                rule: (val) =>
                  !startTime ||
                  isStartTimeBeforeEndTime({
                    startTime,
                    endTime: val,
                  }),
                message: "L'heure de fin est avant l'heure de début",
                deps: [startTime],
              },
            ]}
          />
        </Flex>
        <FieldInput
          type="hidden"
          name="helperDateText"
          helper="La durée minimum facturée est d'une heure"
          mt={4}
        />
      </Box>
    </>
  );
};
