import { Server, Model, Serializer } from 'miragejs';

import { API_URL } from '@/services/config';

import { agencyFactory } from './agency';
import { appointmentFactory } from './appointment';
import { invoiceRequestFactory } from './invoiceRequest';
import { userFactory } from './user';

const AppSerializer = Serializer.extend({
  embed: true,
  root: false,
});

// const getUserIdFromToken = (request) => {
//   const { requestHeaders } = request;
//   const { Authorization } = requestHeaders;
//   return Authorization.split('Bearer ')[1];
// };

export function fakeServer({ environment = 'development' } = {}) {
  const server = new Server({
    serializers: {
      application: AppSerializer,
    },
    environment,

    models: {
      invoiceRequest: Model,
      user: Model,
      agency: Model,
      appointment: Model,
    },

    factories: {
      user: userFactory,
      invoiceRequest: invoiceRequestFactory,
      agency: agencyFactory,
      appointment: appointmentFactory,
    },

    seeds(serv) {
      const users = serv.createList('user', 10);
      serv.createList('agency', 10);
      serv.createList('appointment', 10, { userId: users[0].attrs.id });
      serv.createList('invoiceRequest', 10, { userId: users[0].attrs.id });
    },

    routes() {
      this.urlPrefix = API_URL;
      // this.namespace = '/api/v1';
      // endpoint for registration

      // this.post('/registrations', (schema, request) => {
      //   const newUser = server.create('user');
      //   const { login } = JSON.parse(request.requestBody);
      //   if (login === 'error409@gmail.com') {
      //     return new Response(409, {}, { errors: ['Email already exist'] });
      //   }
      //   if (login === 'error@gmail.com') {
      //     return new Response(
      //       500,
      //       {},
      //       { errors: ['An error occured during registration'] }
      //     );
      //   }
      //   return schema.users.create(newUser.attrs);
      // });

      // // endpoint for login
      // this.post('/authenticate', (schema, request) => {
      //   const { login } = JSON.parse(request.requestBody);
      //   if (login === 'error401@gmail.com') {
      //     return new Response(401, {}, { errors: ['Incorrect credentials'] });
      //   }
      //   if (login === 'error@gmail.com') {
      //     return new Response(
      //       500,
      //       {},
      //       { errors: ['An error occurred while connecting'] }
      //     );
      //   }
      //   const user =
      //     schema?.users?.all()?.models?.find((u) => u.email === login) ||
      //     schema.users.first().attrs;
      //   return { token: user.id, ...user };
      // });

      // endpoint for init reseting passwords

      // this.post('/account', (schema, request) => {
      //   const updatedUserInfo = JSON.parse(request.requestBody);
      //   const { login } = updatedUserInfo;
      //   if (login === 'error409@gmail.com') {
      //     return new Response(409, {}, { errors: ['Email already exist'] });
      //   }
      //   if (login === 'error@gmail.com') {
      //     return new Response(
      //       500,
      //       {},
      //       { errors: ['An error occured during updating account'] }
      //     );
      //   }
      //   return new Response(200, {}, updatedUserInfo);
      // });

      // this.post('/reset_password/init', (schema, request) => {
      //   const { email } = JSON.parse(request.requestBody);
      //   if (email === 'error@gmail.com') {
      //     return new Response(
      //       500,
      //       {},
      //       { errors: ['Error while sending mail'] }
      //     );
      //   }
      //
      //   if (email === 'notfound@gmail.com') {
      //     return new Response(404, {}, { errors: ['Email was not found'] });
      //   }
      //
      //   return new Response(
      //     200,
      //     {},
      //     { message: ['Email was successfully sent'] }
      //   );
      // });

      // endpoint for reseting passwords
      // this.post('/reset_password/change', (schema, request) => {
      //   const { newPassword, token } = JSON.parse(request.requestBody);
      //   if (newPassword && token === '123456') {
      //     return new Response(200, {}, { message: ['Password was changed'] });
      //   }
      //
      //   if (token !== '123456') {
      //     return new Response(
      //       404,
      //       {},
      //       { message: ['Token invalide or expired'] }
      //     );
      //   }
      //   return new Response(
      //     500,
      //     {},
      //     { errors: ['Error occured while changing passwords'] }
      //   );
      // });

      // this.post('/change_password', (schema, request) => {
      //   const { oldPassword } = JSON.parse(request.requestBody);
      //   if (oldPassword === 'oldPassword') {
      //     return new Response(200, {}, { message: ['Password was changed'] });
      //   }
      //
      //   if (oldPassword === '401401401') {
      //     return new Response(
      //       401,
      //       {},
      //       { message: ['Unauthorized, wrong password'] }
      //     );
      //   }
      //   return new Response(
      //     500,
      //     {},
      //     { errors: ['Error occured while changing passwords'] }
      //   );
      // });

      // endpoint to get agencies
      // this.get('/agencies', (schema, request) => {
      //   const data = request.queryParams;
      //   // eslint-disable-next-line no-console
      //   console.log({ data });
      //   if (data?.subject === BOOKING_REQUEST_SUBJECTS.HEALTH.id) {
      //     return schema.agencies.all()?.slice(0, 5);
      //   }
      //   if (data?.subject === BOOKING_REQUEST_SUBJECTS.MEETING.id) {
      //     return schema.agencies.all()?.slice(0, 8);
      //   }
      //   if (data?.subject === BOOKING_REQUEST_SUBJECTS.EVENT.id) {
      //     return schema.agencies.all()?.slice(0, 3);
      //   }
      //   return [];
      // });

      // endpoint to add invoice request
      // this.post('/invoice-requests', (schema, request) => {
      //   const {
      //     agencyIds,
      //     appointmentAddress,
      //     invoiceAddress,
      //     firstName,
      //     lastName,
      //     email,
      //     phoneNumber,
      //     startDate,
      //     endDate,
      //     subject,
      //     health,
      //     justice,
      //     event,
      //     meeting,
      //   } = JSON.parse(request.requestBody);
      //   // on doit avoir au moint un des 4 type
      //   const userId = getUserIdFromToken(request);
      //   if (phoneNumber === '000000000') {
      //     return new Response(415, {}, { errors: ['Invalide parameters'] });
      //   }
      //
      //   return schema.invoiceRequests.create({
      //     agencyIds,
      //     subject,
      //     appointmentAddress,
      //     invoiceAddress,
      //     firstName,
      //     lastName,
      //     email,
      //     phoneNumber,
      //     startDate,
      //     endDate,
      //     health,
      //     justice,
      //     event,
      //     meeting,
      //     userId,
      //   });
      // });

      // endpoint to get current user
      this.get('/users/me', (schema) => schema.users.first());

      // endpoint to update current user
      this.put('/users/me', (schema) =>
        schema.users.first().update({ firstName: 'updatedFirstName' })
      );

      // endpoint to list all user invoice request
      // this.get('/invoice-requests', (schema) => {
      //   const invoices = schema.invoiceRequests.all();
      //   return invoices;
      // });

      // endpoint to get user invoice request by id
      // this.get('/invoice-requests/:id', (schema, request) => {
      //   const { id } = request.params;
      //   const res = schema.invoiceRequests.findBy((inv) => inv.id === id);
      //   return res;
      // });

      // endpoint to update invoice request
      this.put('/invoice-requests', (schema, request) => {
        const {
          id,
          agencyIds,
          themeType,
          // eslint-disable-next-line no-unused-vars
          ...requestData
        } = JSON.parse(request.requestBody);
        return schema.invoiceRequests.first().update({});
      });

      // endpoint to cancel invoice request
      // this.patch('/invoice-requests/:id/cancel', (schema, request) => {
      //   const { id } = request.params;
      //   schema.invoiceRequests.find(id).destroy();
      // });

      // endpoint to refuse invoice request response
      // this.patch(
      //   '/invoice-requests/:id/responses/:responseId/refuse',
      //   (schema, request) => {
      //     const { id, responseId } = request.params;
      //     const invoice = schema.invoiceRequests.findBy((inv) => inv.id === id);
      //     invoice.update({
      //       responses: invoice?.attrs?.responses?.map((res) =>
      //         res?.id === responseId
      //           ? { ...res, status: INVOICE_REQUEST_RESPONSES_STATUS.REFUSED }
      //           : res
      //       ),
      //     });
      //   }
      // );

      // endpoint to accept invoice request response
      // this.patch(
      //   '/invoice-requests/:invoiceRequestId/responses/:responseId/accept',
      //   (schema, request) => {
      //     const { invoiceRequestId } = request.params;
      //     schema.invoiceRequests.find(invoiceRequestId).destroy();
      //   }
      // );

      // endpoint to get all user appointments
      // this.get('/appointments');
      // endpoint to get user appointment detail
      // this.get('/appointment/:id');

      // endpoint to find address ???
      this.get('/address/:search');

      // endpoint to store documents
      this.post('/medias/');

      this.passthrough(`${API_URL}/**`);
    },
  });

  return server;
}
