import React, { useEffect } from 'react';

import { Flex, Spacer, Stack } from '@chakra-ui/react';
import { useForm } from '@formiz/core';

import { FieldRadioGroup } from '@/components';
import {
  BOOKING_REQUEST_CATEGORY,
  BOOKING_REQUEST_SUBJECTS,
  BOOKING_REQUEST_SUBJECTS_PROFESSIONAL,
} from '@/constants/bookingRequest';
import { BOOKING_REQUEST_STORAGE_KEY } from '@/constants/invoiceRequest';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';
import { RadioItem } from '@/pages/BookingRequest/_partials/RadioItem';
import { useLocalStorage } from '@/services/localStorage';

const { MEETING, EVENT, HEALTH, JUSTICE } = BOOKING_REQUEST_SUBJECTS;
const {
  PROFESSIONAL_MEETING,
  PROFESSIONAL_EVENT,
  PROFESSIONAL_HEALTH,
} = BOOKING_REQUEST_SUBJECTS_PROFESSIONAL;
const subjectsOptions = {
  [BOOKING_REQUEST_CATEGORY.PERSONAL.id]: [HEALTH, MEETING, EVENT, JUSTICE],
  [BOOKING_REQUEST_CATEGORY.PROFESSIONAL.id]: [
    PROFESSIONAL_MEETING,
    PROFESSIONAL_EVENT,
    PROFESSIONAL_HEALTH,
  ],
};

export const AppointmentSubject = () => {
  const {
    setFieldsValues,
    values: { appointment },
  } = useForm({ subscribe: { fields: ['appointment.category'] } });
  const { category } = appointment || { category: null };

  const [bookingRequest] = useLocalStorage(BOOKING_REQUEST_STORAGE_KEY, '');

  const { category: storageCategory } = bookingRequest?.appointment || {};

  useEffect(() => {
    if (category && storageCategory !== category) {
      setFieldsValues({ 'appointment.subject': '' });
    }
  }, [setFieldsValues, category, storageCategory]);

  return (
    <>
      <Flex>
        <AppointmentHeading
          title="Thème du rendez-vous"
          helper="Quelle est la thématique de votre rendez-vous ?"
        />
        <Spacer />
      </Flex>
      <FieldRadioGroup
        id="appointment-subject"
        name="appointment.subject"
        required="Vous devez choisir un thème"
        mt="4"
      >
        <Stack>
          {(subjectsOptions[category] || []).map((subject) => (
            <RadioItem key={subject.id} {...subject} />
          ))}
        </Stack>
      </FieldRadioGroup>
    </>
  );
};
