import React, { useState, useEffect } from 'react';

import { Box, Flex, Spacer } from '@chakra-ui/react';
import { useForm } from '@formiz/core';

import { FieldInput } from '@/components';
import { BOOKING_REQUEST_DATE_TIME } from '@/constants/bookingRequest';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';
import { useDateTimeRepeater } from '@/services/bookingRequest';

import { AppointmentButtonPlaceHolder } from './AppointmentButtonPlaceHolder';
import {
  hasDateOverlap,
  AppointmentMultiDateRepeaterForm,
} from './AppointmentMultiDateRepeaterForm';

export const AppointmentMultiDateInformation = () => {
  const {
    dateCollection: meetingDateCollection,
    dateFormRef,
    deleteSelectedDate,
    addDate: addDateMeeting,
  } = useDateTimeRepeater({
    dateCount: 10,
    dateType: BOOKING_REQUEST_DATE_TIME.MULTI_DATES.id,
  });
  const form = useForm({
    subscribe: {
      form: true,
      fields: ['appointment.datetime.dates'],
    },
  });
  const { values } = form;
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const valuesAppointmentDatetimeDates = values?.appointment.datetime.dates;
  const formCurrentStep = form?.currentStep;

  useEffect(() => {
    const hasOverlap = hasDateOverlap(
      valuesAppointmentDatetimeDates,
      formCurrentStep
    );
    setShowErrorMessage(hasOverlap);
  }, [valuesAppointmentDatetimeDates, formCurrentStep]);

  return (
    <>
      <Flex>
        <AppointmentHeading
          title="Rendez-vous multi-dates"
          helper="Enregistrez jusqu’à 10 rendez-vous identiques"
        />
        <Spacer />
      </Flex>
      <Box>
        <>
          {meetingDateCollection?.map((meetingDate, i) => (
            <AppointmentMultiDateRepeaterForm
              ref={(el) => {
                dateFormRef.current[i] = el;
              }}
              key={meetingDate.id}
              index={i}
              deleteButtonProps={{
                isDisabled: meetingDateCollection?.length === 1,
                onClick: () => deleteSelectedDate(meetingDate.id),
              }}
            />
          ))}
          <AppointmentButtonPlaceHolder
            onClick={addDateMeeting}
            selectedDateCount={meetingDateCollection?.length}
            isDisabled={meetingDateCollection?.length >= 10}
            maxDatesCount={10}
          />
          <FieldInput
            type="hidden"
            name="helperDateText"
            helper="La durée minimum facturée est d'une heure"
            validations={[
              {
                rule: () =>
                  hasDateOverlap(
                    values?.appointment.datetime.dates,
                    form?.currentStep
                  ),
                deps: [values?.appointment.datetime],
              },
            ]}
            mt={4}
          />
          {!showErrorMessage && (
            <span style={{ color: 'red' }}>
              Il y a un conflit de rendez-vous. Veuillez sélectionner une plage
              horaire différente.
            </span>
          )}
        </>
      </Box>
    </>
  );
};
