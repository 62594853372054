import React from 'react';

import { Flex, Spacer } from '@chakra-ui/react';
import { FormizStep, useForm } from '@formiz/core';

import { FieldSelectAddress } from '@/components';
import { FORM_STEPS } from '@/constants/steps';
import { useCompleteSearchAddress } from '@/services/utils';

import { AppointmentHeading } from '../_partials/AppointmentHeading';

export const AppointmentLocation = () => {
  const { currentStep } = useForm();
  const {
    searchOptions,
    searchText,
    isOptionLoading,
    setSearchText,
  } = useCompleteSearchAddress({
    enabled: currentStep?.name === FORM_STEPS.LOCATION.ADDRESS.name,
  });

  return (
    <FormizStep
      name={FORM_STEPS.LOCATION.ADDRESS.name}
      order={FORM_STEPS.LOCATION.ADDRESS.order}
    >
      <Flex>
        <AppointmentHeading
          title="Lieu de votre rendez-vous"
          helper="Où a lieu le rendez-vous ?"
          mb={4}
        />
        <Spacer />
      </Flex>

      <FieldSelectAddress
        name="appointment.location"
        id="location"
        label="Adresse du rendez-vous"
        placeholder="Où allez-vous ?"
        required="Vous devez entrer une adresse"
        inputValue={searchText}
        onInputChange={setSearchText}
        validations={[
          {
            rule: (val) =>
              !val || (val?.zipCode && val?.streetAddress && val?.city),
            message: 'Vous devez saisir une adresse complète',
          },
        ]}
        options={searchOptions}
        isOptionLoading={isOptionLoading}
        noOptionsMessage={
          isOptionLoading
            ? 'Recherche en cours...'
            : 'Aucune adresse disponible'
        }
      />
    </FormizStep>
  );
};
