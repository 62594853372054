import React, { useEffect, useState } from 'react';

import { Box, useToast } from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { useHistory, useLocation } from 'react-router-dom';

import { PreviewModal } from '@/components';
import { BOOKING_REQUEST_STORAGE_KEY } from '@/constants/invoiceRequest';
import { FORM_STEPS } from '@/constants/steps';
import { Page } from '@/layout';
import { useAgencies } from '@/services/agencies';
import { convertToSearchDto } from '@/services/bookingRequest';
import { useLocalStorage } from '@/services/localStorage';

import { BookingRequestFooter } from './_partials/BookingRequestFooter';
import { BookingRequestHeader } from './_partials/BookingRequestHeader';
import { BookingRequestStepper } from './_partials/BookingRequestStepper';
import { helpIconContext } from './_partials/HelpIconProvider';
import {
  AppointmentDateTime,
  AppointmentInformation,
  AppointmentLocation,
  BookingSummary,
} from './_steps';

export const BookingRequest = () => {
  const form = useForm();
  const [ableToHistoryPush, setAbleToHistoryPush] = useState(false);
  const {
    currentStep,
    goToStep,
    submitStep,
    steps,
    values: { appointment },
  } = form;

  const { isPreviewModalOpen, onClosePreviewModal } = React.useContext(
    helpIconContext
  );
  const history = useHistory();

  const { state } = useLocation();
  const { goToSummaryStep } = state || {};

  const [bookingRequest, setBookingRequest] = useLocalStorage(
    BOOKING_REQUEST_STORAGE_KEY,
    ''
  );

  const convertedSearchDTO = convertToSearchDto({ appointment });

  const toast = useToast();

  const { data: agencies, isLoading } = useAgencies({
    params: convertedSearchDTO,
    enabled: form.currentStep?.name === FORM_STEPS.SUMMARY.name,
    onError: () => {
      toast({
        title:
          'Une erreur est survenue lors de la recherche des agences disponibles',
        status: 'error',
      });
    },
  });

  const handleValidSubmit = (values) => {
    setBookingRequest(values);
    if (ableToHistoryPush === false) {
      history.push('invoice-request');
    } else {
      setAbleToHistoryPush(false);
    }
  };

  useEffect(() => {
    if (goToSummaryStep && goToStep) {
      goToStep(goToSummaryStep?.name);
    }
  }, [bookingRequest, goToStep, goToSummaryStep]);

  const handleSubmitStep = (event) => {
    submitStep(event);
  };

  const disableNextButton =
    currentStep?.index >=
      steps?.find((step) => step?.name === FORM_STEPS.SUMMARY.name)?.index &&
    !agencies?.length;

  return (
    <Page px={0} pt={0} pb={4} backgroundColor="gray.100">
      <Box>
        <Formiz
          connect={form}
          onValidSubmit={handleValidSubmit}
          initialValues={goToSummaryStep ? { ...bookingRequest } : null}
        >
          <form noValidate onSubmit={handleSubmitStep}>
            <BookingRequestHeader title="Demande de réservation" />
            <Box px={{ base: 4, sm: 0 }} backgroundColor="brandSecondary.600">
              <Box position="relative" marginX="auto" maxWidth="45em">
                <BookingRequestStepper />
              </Box>
            </Box>
            <Box
              px={{ base: 4, sm: 0 }}
              mt={{ base: 4, sm: 10 }}
              pb={{ base: 4, sm: 10 }}
              marginX="auto"
              maxWidth="45em"
            >
              <AppointmentLocation />
              <AppointmentInformation />
              <AppointmentDateTime />
              <BookingSummary
                agencies={agencies}
                isAgenciesLoading={isLoading}
              />

              <BookingRequestFooter
                isDisabledNextButton={disableNextButton}
                appointment={appointment}
                setAbleToHistoryPush={setAbleToHistoryPush}
              />
            </Box>
          </form>
        </Formiz>
        <PreviewModal
          onClose={onClosePreviewModal}
          isOpen={isPreviewModalOpen}
          url="videos/placeholder.mp4"
        />
      </Box>
    </Page>
  );
};
