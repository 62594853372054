import React from 'react';

import { Flex, Spacer, Stack } from '@chakra-ui/react';

import { FieldRadioGroup } from '@/components';
import { BOOKING_REQUEST_CATEGORY } from '@/constants/bookingRequest';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';
import { RadioItem } from '@/pages/BookingRequest/_partials/RadioItem';

export const AppointmentCategory = () => (
  <>
    <Flex>
      <AppointmentHeading
        title="Objet de votre rendez-vous"
        helper="Quel est le motif de votre rendez-vous ?"
      />
      <Spacer />
    </Flex>
    <FieldRadioGroup
      id="appointment-category"
      name="appointment.category"
      required="Vous devez choisir un thème"
      mt="4"
    >
      <Stack>
        <RadioItem {...BOOKING_REQUEST_CATEGORY.PERSONAL} />
        <RadioItem {...BOOKING_REQUEST_CATEGORY.PROFESSIONAL} />
      </Stack>
    </FieldRadioGroup>
  </>
);
