import React from 'react';

import {
  Box,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import {
  isEmail,
  isMinLength,
  isNotEmptyString,
  isPattern,
} from '@formiz/validations';
import { FaArrowLeft } from 'react-icons/fa';
import { Redirect, useHistory } from 'react-router-dom';

import { FieldCheckbox, FieldInput, Footer, Link, Logo } from '@/components';
import { useUserContext } from '@/context/user-context';
import { Page } from '@/layout';
import { useQueryParam } from '@/services/queryParam';
import { useRegister } from '@/services/register';
import {
  formatInternationalPhoneNumber,
  isValidFrenchPhoneNumber,
  HAS_DIGIT,
  HAS_LOWCASE_CHARACTER,
  HAS_UPPERCASE_CHARACTER,
} from '@/utils/formCustomValidations';

import { RegisterFooter } from './_partials/RegisterFooter.';

export const Register = () => {
  const { isLogged } = useUserContext();
  const history = useHistory();
  const toast = useToast();
  const redirect = useQueryParam('redirect');
  const registerForm = useForm();
  const isDesktopScreen = useBreakpointValue({ base: false, md: true });

  const [mutate, { isLoading }] = useRegister({
    onError: ({ response }) => {
      if (response?.status === 400) {
        toast({
          title: response?.data
            ? `${response?.data}`
            : 'Il y a un attribut invalide',
          status: 'error',
        });
      } else if (response?.status === 409) {
        toast({
          title: 'Un autre compte possède déjà cette adresse mail',
          status: 'error',
        });
      } else if (response?.status === 503) {
        toast({
          title:
            "Trop de tentatives d'inscription, veuillez réessayer dans quelques minutes",
          status: 'error',
        });
      } else {
        toast({
          title: "Une erreur est survenue lors de l'inscription",
          status: 'error',
        });
      }
    },

    onSuccess: () => {
      toast({
        title: 'Votre compte a été créé avec succès',
        status: 'success',
      });
      if (redirect) {
        history.push(redirect);
      } else {
        history.push('login');
      }
    },
  });

  const handleSubmit = (values) => {
    const { ...otherValues } = values;
    mutate(otherValues);
  };

  if (isLogged) {
    return <Redirect to="/" />;
  }

  return (
    <Page px={6} mt={0} haveFooter={!isDesktopScreen} backgroundColor="gray.50">
      <Box maxW="45em" mx="auto">
        <IconButton
          icon={<FaArrowLeft />}
          color="gray.400"
          variant="ghost"
          onClick={() => history.push('login')}
        />
        <Formiz connect={registerForm} onValidSubmit={handleSubmit}>
          <Box as="form" noValidate onSubmit={registerForm.submit} mb={10}>
            <Flex direction="column" align="center" m="auto" maxW="400px">
              <Logo as={Link} mb={{ base: 4, sm: 6 }} width="4rem" to="/" />
              <Heading as="h1" size="lg" mb={6}>
                Inscription
              </Heading>
              <Box w="100%">
                <Text textAlign="center" mb={10}>
                  Vous devez être connecté pour aller plus loin.{' '}
                </Text>
                <FieldInput
                  name="login"
                  type="email"
                  required="L'adresse email est requise"
                  placeholder="Votre email"
                  label={
                    <span>
                      Email<span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  validations={[
                    {
                      rule: isEmail(),
                      message: "L'adresse email n'est pas valide",
                    },
                  ]}
                />
                <FieldInput
                  name="firstName"
                  label={
                    <span>
                      Prénom<span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  placeholder="Votre prénom"
                  required="Le prénom est requis"
                  validations={[
                    {
                      rule: isNotEmptyString(),
                      message: 'Le prénom est requis',
                    },
                  ]}
                />
                <FieldInput
                  name="lastName"
                  label={
                    <span>
                      Nom<span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  placeholder="Votre nom"
                  required="Le nom est requis"
                  validations={[
                    {
                      rule: isNotEmptyString(),
                      message: 'Le nom est requis',
                    },
                  ]}
                />
                <FieldInput
                  name="phoneNumber"
                  label="Téléphone"
                  placeholder="Votre numéro de téléphone"
                  formatValue={formatInternationalPhoneNumber}
                  validations={[
                    {
                      rule: isValidFrenchPhoneNumber(),
                      message: "Votre numéro de téléphone n'est pas valide",
                    },
                  ]}
                />
                <FieldInput
                  name="password"
                  type="password"
                  placeholder="Choisissez un mot de passe"
                  required="Le mot de passe est requis"
                  validations={[
                    {
                      rule: isMinLength(8),
                      message:
                        'Le mot de passe doit avoir au moins 8 caractères',
                    },
                    {
                      rule: (value) =>
                        isPattern(HAS_DIGIT)(value) ||
                        (isPattern(HAS_LOWCASE_CHARACTER)(value) &&
                          isPattern(HAS_UPPERCASE_CHARACTER)(value)),
                      message:
                        'Le mot de passe doit contenir soit un mélange de majuscules' +
                        ' et de minuscule, soit au moins un chiffre',
                      deps: [registerForm.values.password],
                    },
                  ]}
                  label={
                    <span>
                      Mot de passe<span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                />
                <FieldInput
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirmez un mot de passe"
                  required="Le mot de passe est requis"
                  validations={[
                    {
                      rule: (value) => registerForm.values.password === value,
                      message: 'Le mot de passe ne correspond pas',
                      deps: [registerForm.values.password],
                    },
                  ]}
                  label={
                    <span>
                      Confirmez le mot de passe
                      <span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  h="6rem"
                />

                <FieldCheckbox
                  name="areAcceptedAllDocuments"
                  mb="20px"
                  required="Vous devez accepter les conditions"
                  label={
                    <Box fontWeight={400}>
                      J&apos;accepte la{' '}
                      <Link
                        to="/pdf/Politique de confidentialité résa'pi.pdf"
                        target="_blank"
                        color="brandSecondary.600"
                      >
                        politique de confidentialité
                      </Link>{' '}
                      et les{' '}
                      <Link
                        to="/pdf/CGU résa'pi.pdf"
                        target="_blank"
                        color="brandSecondary.600"
                      >
                        conditions générales d&apos;utilisation
                      </Link>
                    </Box>
                  }
                />
                <FieldCheckbox
                  name="areAcceptedDataProcessing"
                  mb="20px"
                  required="Vous devez accepter le traitement des données"
                  label={
                    <Box fontWeight={400}>
                      J&apos;accepte le traitement par Résapi de l&apos;ensemble
                      des données saisies, y compris les données sensibles en
                      fonction de la nature des prestations demandées
                    </Box>
                  }
                />

                <Box marginTop={4}>
                  <span style={{ color: 'red' }}> *</span> Mentions obligatoires
                </Box>

                {isDesktopScreen ? (
                  <Stack mt={4} spacing={4}>
                    <RegisterFooter isLoading={isLoading} />
                  </Stack>
                ) : null}
              </Box>
            </Flex>

            {!isDesktopScreen ? (
              <Footer as={Stack} spacing={4} zIndex={3}>
                <RegisterFooter isLoading={isLoading} />
              </Footer>
            ) : null}
          </Box>
        </Formiz>
      </Box>
    </Page>
  );
};
