import React from 'react';

import { Box } from '@chakra-ui/react';
import { FormizStep, useForm } from '@formiz/core';
import { isEmail, isNotEmptyString } from '@formiz/validations';

import { FieldInput } from '@/components';
import {
  INVOICE_REQUEST_STEPS,
  INVOICE_REQUEST_BENEFICIARIES,
} from '@/constants/invoiceRequest';
import {
  formatInternationalPhoneNumber,
  isValidFrenchPhoneNumber,
} from '@/utils/formCustomValidations';

import { InvoiceRequestHeading } from '../_partials/InvoiceRequestHeading';

export const InvoiceRequestBeneficiaryInformation = () => {
  const form = useForm({ subscribe: { fields: ['beneficiary'] } });
  return (
    <FormizStep
      name={INVOICE_REQUEST_STEPS.PERSONAL_INFORMATION.name}
      order={INVOICE_REQUEST_STEPS.PERSONAL_INFORMATION.order}
      isEnabled={
        form?.values?.beneficiary &&
        form?.values?.beneficiary !== INVOICE_REQUEST_BENEFICIARIES.YOU
      }
    >
      <InvoiceRequestHeading
        title="Information sur le participant"
        helper="Les informations suivantes servent à définir à qui s'adresse le rendez-vous."
        mb={4}
      />
      <FieldInput
        name="firstName"
        label={
          <span>
            Prénom<span style={{ color: 'red' }}> *</span>
          </span>
        }
        required="Le nom du bénéficiaire est requis"
        placeholder="Nom du bénéficiaire"
        mt={20}
        validations={[
          {
            rule: isNotEmptyString(),
            message: 'Le nom du bénéficiaire est requis',
          },
        ]}
      />
      <FieldInput
        name="lastName"
        label={
          <span>
            Nom<span style={{ color: 'red' }}> *</span>
          </span>
        }
        required="Le prénom du bénéficiaire est requis"
        placeholder="Prénom du bénéficiaire"
        mt={20}
        validations={[
          {
            rule: isNotEmptyString(),
            message: 'Le prénom du bénéficiaire est requis',
          },
        ]}
      />
      <FieldInput
        name="email"
        label={
          <span>
            Adresse email de contact<span style={{ color: 'red' }}> *</span>
          </span>
        }
        placeholder="Adresse email du bénéficiaire"
        required="L'adresse email de bénéficiare est requise"
        mt={20}
        validations={[
          {
            rule: isEmail(),
            message: "L'adresse email de bénéficiare est requise",
          },
        ]}
      />
      <FieldInput
        name="phoneNumber"
        label={
          <span>
            Numéro de téléphone portable<span style={{ color: 'red' }}> *</span>
          </span>
        }
        placeholder="Numéro du bénéficiaire"
        required="Le numéro de téléphone est requis"
        mt={20}
        formatValue={formatInternationalPhoneNumber}
        validations={[
          {
            rule: isNotEmptyString(),
            message: 'Le numéro de téléphone est requis',
          },
          {
            rule: isValidFrenchPhoneNumber(),
            message: "Votre numéro de téléphone n'est pas valide",
          },
        ]}
      />
      <Box marginTop={20}>
        <span style={{ color: 'red' }}> *</span> Mentions obligatoires
      </Box>
    </FormizStep>
  );
};
