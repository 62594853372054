import React, { useState } from 'react';

import {
  Button,
  Flex,
  IconButton,
  VisuallyHidden,
  useBreakpointValue,
  useTheme,
  Box,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaBars, FaRegCalendar } from 'react-icons/fa';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { User } from '@/assets/icons';
import {
  Header,
  HeaderLeftAction,
  HeaderRightAction,
  HeaderTitle,
  Icon,
  Logo,
} from '@/components';
import { useUserContext } from '@/context/user-context';

import { LoggedInMenuOptions, MobileMenu } from './_partials';

export const AppHeader = ({ title, ...otherProps }) => {
  const location = useLocation();
  const isHomePage = location?.pathname === '/';
  const activeHeaderColor = isHomePage ? 'brand' : 'brandSecondary';

  const theme = useTheme();
  const isWide = useBreakpointValue({ base: false, md: true });
  const { isLogged } = useUserContext();

  const [isMobileMenuVisible, setShowMobileMenu] = useState(false);
  const toggleMobileMenu = () => setShowMobileMenu((x) => !x);

  return (
    <Header position="fixed" {...otherProps} height="4.5rem">
      <Box position="relative" w="100%" h="100%">
        {/* Left Action */}
        <HeaderLeftAction>
          <Button
            as={Link}
            to="/"
            variant="link"
            h="100%"
            width={{ base: 10, md: 40 }}
            mx={4}
            p={0}
            isActive
            display="block"
            _hover={{
              textDecoration: 'none',
              color: theme.colors.brandSecondary[600],
            }}
            _active={{
              boxShadow: 'none',
            }}
          >
            <VisuallyHidden>Retour sur la page d&apos;accueil</VisuallyHidden>
            <Logo as="span" isFull={isWide} />
          </Button>
        </HeaderLeftAction>
        {title && !isWide ? <HeaderTitle>{title}</HeaderTitle> : ''}
        {/* RightAction */}
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLogged ? (
          <HeaderRightAction>
            <Flex>
              {isWide ? (
                <>
                  <Button
                    as={NavLink}
                    to="/agenda"
                    {...(isHomePage
                      ? {
                          colorScheme: 'brand',
                          color: 'brand.800',
                          fontWeight: 700,
                        }
                      : {
                          colorScheme: 'gray',
                        })}
                    activeStyle={{
                      color: theme.colors[activeHeaderColor][600],
                    }}
                    _active={{
                      bg: theme.colors[activeHeaderColor][100],
                    }}
                    _hover={{
                      color: theme.colors[activeHeaderColor][700],
                      bg: theme.colors[activeHeaderColor][50],
                    }}
                    variant="ghost"
                  >
                    <Icon mr={2} icon={FaRegCalendar} />
                    Agenda
                  </Button>
                  <Button
                    as={NavLink}
                    to="/bookings"
                    {...(isHomePage
                      ? {
                          colorScheme: 'brand',
                          color: 'brand.800',
                          fontWeight: 700,
                        }
                      : {
                          colorScheme: 'gray',
                        })}
                    activeStyle={{
                      color: theme.colors[activeHeaderColor][600],
                    }}
                    _active={{
                      bg: theme.colors[activeHeaderColor][100],
                    }}
                    _hover={{
                      color: theme.colors[activeHeaderColor][700],
                      bg: theme.colors[activeHeaderColor][50],
                    }}
                    variant="ghost"
                  >
                    <Icon mr={2} icon={FaBars} />
                    Demandes
                  </Button>
                </>
              ) : null}

              <LoggedInMenuOptions
                menuButtonProps={{
                  as: isWide ? Button : IconButton,
                }}
              >
                <Icon
                  icon={User}
                  iconProps={{ w: '1.25em', h: '1.25em' }}
                  mr={isWide ? 2 : 0}
                />
                {isWide ? 'Mon compte' : ''}
              </LoggedInMenuOptions>
            </Flex>
          </HeaderRightAction>
        ) : (
          // !isLogged
          <HeaderRightAction>
            {isWide ? (
              <>
                {/* <Button
                  as={Link}
                  to="/help"
                  {...(isHomePage
                    ? {
                        colorScheme: 'brand',
                        color: 'brand.800',
                      }
                    : {
                        colorScheme: 'brandSecondary',
                      })}
                  fontWeight="700"
                  variant="ghost"
                  fontFamily={theme.fonts.body}
                  mr={2}
                >
                  Besoin d&apos;aide?
                </Button> */}
                <Button
                  as={Link}
                  to="/login"
                  {...(isHomePage
                    ? {
                        bg: 'brand.200',
                        variant: 'solid',
                        color: 'brand.800',
                      }
                    : {
                        colorScheme: 'brandSecondary',
                        variant: 'ghost',
                      })}
                  fontWeight="700"
                  _hover={{
                    bg: 'brand.400',
                  }}
                  _focus={{
                    boxShadow: 'outline',
                  }}
                  _active={{
                    bg: 'brand.400',
                  }}
                  fontFamily={theme.fonts.body}
                >
                  Se connecter
                </Button>
              </>
            ) : (
              <IconButton
                colorScheme={activeHeaderColor}
                variant="ghost"
                display={{ base: 'flex', md: 'none' }}
                onClick={toggleMobileMenu}
                icon={<FaBars />}
                aria-label="Ouvrir le menu"
              />
            )}
          </HeaderRightAction>
        )}

        {/* Header Menu */}
        {!isWide && !isLogged && isMobileMenuVisible ? (
          <MobileMenu onCloseMobileMenu={() => setShowMobileMenu(false)} />
        ) : (
          ''
        )}
      </Box>
    </Header>
  );
};

AppHeader.propTypes = {
  title: PropTypes.node,
};

AppHeader.defaultProps = {
  title: null,
};
