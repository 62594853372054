import React, { useState } from 'react';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  Button,
  useToast,
  Box,
  Text,
} from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import PropTypes from 'prop-types';
import { FiAlertTriangle } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { FieldCheckbox } from '@/components';
import { useNewCurrentDateCGU } from '@/services/cgu';

export const ModalForCGU = ({ isModalCGU, setIsModalCGU }) => {
  const toast = useToast();
  const form = useForm();

  const [checked, setChecked] = useState(false);

  const [updateNewCGU, { isLoading }] = useNewCurrentDateCGU({
    onError: () => {
      toast({
        title:
          "Une erreur est survenue lors de l'acceptation des nouvelles CGU",
        status: 'error',
      });
    },
    onSuccess: () => {
      toast({
        title: 'Les nouvelles CGU ont été acceptées avec succès',
        status: 'success',
      });
    },
  });

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleValidSubmit = (values) => {
    const cguData = { useAsSaveDateCGU: values?.useAsSaveDateCGU };
    updateNewCGU(cguData);
    setChecked(false);
  };

  return (
    <Modal
      isOpen={isModalCGU}
      size="lg"
      onClose={() => setIsModalCGU(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay>
        <ModalContent bgColor="gray.50">
          <ModalHeader>
            <Flex>
              <FiAlertTriangle style={{ width: '40px', height: '30px' }} />
              <Text>
                Une nouvelle version des
                <Link
                  to="/pdf/CGU résa'pi.pdf"
                  target="_blank"
                  style={{
                    marginRight: '5px',
                    marginLeft: '5px',
                    textDecoration: 'underline',
                  }}
                >
                  CGU
                </Link>
                est disponible
              </Text>
            </Flex>
          </ModalHeader>
          <Formiz connect={form} onSubmit={handleValidSubmit}>
            <Box
              as="form"
              noValidate
              onSubmit={form.submit}
              onChange={handleChange}
            >
              <ModalBody>
                <FieldCheckbox
                  name="useAsSaveDateCGU"
                  defaultValue={false}
                  isDisabled={isLoading}
                  label="J'accepte les nouvelles conditions générales d'utilisation"
                  checkboxProps={{
                    float: 'right',
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  colorScheme="brandSecondary"
                  mr={3}
                  disabled={checked === false}
                  onClick={() => {
                    setIsModalCGU(false);
                  }}
                >
                  Accepter
                </Button>
              </ModalFooter>
            </Box>
          </Formiz>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

ModalForCGU.propTypes = {
  isModalCGU: PropTypes.bool,
  setIsModalCGU: PropTypes.func,
};

ModalForCGU.defaultProps = {
  isModalCGU: false,
  setIsModalCGU: () => {},
};
