import React, { useEffect, useState } from 'react';

import { Route, Switch, useLocation } from 'react-router-dom';

import {
  Agenda,
  BookingDetail,
  BookingRequest,
  Bookings,
  ForgotPassword,
  Help,
  Home,
  InvoiceRequest,
  Login,
  Logout,
  NoMatch,
  Profile,
  Register,
  Password,
} from '@/pages';
import { HelpIconProvider } from '@/pages/BookingRequest/_partials/HelpIconProvider';
import { ChangePassword } from '@/pages/ChangePassword';

import { Authenticated } from './Authenticated';
import { ModalForCGU } from './components/ModalForCGU';
import { ModalForInvalidAccount } from './components/ModalForInvalidAccount';
import {
  useNotValidateAccountDitection,
  useNotActiveAccount,
  useNotValidateCGU,
  useIsActivatedAccount,
} from './config/axios';
import { AppointmentDetail } from './pages/AppointmentDetail';
import { BookingInvoiceRequestAccept } from './pages/BookingInvoiceRequestAccept';

export const Routes = () => {
  const { pathname } = useLocation();

  const [isModalValidateAccount, setIsModalValidateAccount] = useState(false);
  const [isModalCGU, setIsModalCGU] = useState(false);

  useNotActiveAccount({ setIsModalValidateAccount });
  useNotValidateAccountDitection({});
  useNotValidateCGU({ setIsModalCGU });
  useIsActivatedAccount({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Switch>
        <Authenticated
          exact
          path="/invoice-request"
          component={InvoiceRequest}
        />
        <Authenticated exact path="/bookings" component={Bookings} />
        <Authenticated
          exact
          path="/bookings/:bookingId"
          component={BookingDetail}
        />
        <Authenticated
          exact
          path="/bookings/:bookingId/response/:responseId/accept"
          component={BookingInvoiceRequestAccept}
        />
        <Authenticated exact path="/agenda" component={Agenda} />
        <Authenticated
          exact
          path="/agenda/:agendaId"
          component={AppointmentDetail}
        />
        <Authenticated exact path="/profile" component={Profile} />
        <Authenticated exact path="/password" component={Password} />

        {/* Public routes  */}
        <Route exact path="/" component={Home} />
        <Route exact path="/index.html" component={Home} />
        <Route
          exact
          path="/booking-request"
          component={() => (
            <HelpIconProvider>
              <BookingRequest />
            </HelpIconProvider>
          )}
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route
          exact
          path="/forgot-password/:token"
          component={ChangePassword}
        />
        <Route exact path="/help" component={Help} />

        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
      <ModalForInvalidAccount
        isModalValidateAccount={isModalValidateAccount}
        setIsModalValidateAccount={setIsModalValidateAccount}
      />
      <ModalForCGU isModalCGU={isModalCGU} setIsModalCGU={setIsModalCGU} />
    </>
  );
};
