import React from 'react';

import { useMediaQuery } from '@chakra-ui/media-query';
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/lazy';

export const PreviewModal = ({ title, helper, url, isOpen, onClose }) => {
  const modalSize = useBreakpointValue({
    base: 'auto',
    sm: 'auto',
    md: 'auto',
    lg: 'auto',
  });

  const overLoadModalSize = () => {
    return url === 'videos/howItWork.mp4' ? { lg: '100rem' } : { lg: '50rem' };
  };

  const [isViewportLessThan766pxWide] = useMediaQuery('(max-width: 766px)'); // 766px is a responsive switch in this app (?!)

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent
        maxW={overLoadModalSize}
        margin={isViewportLessThan766pxWide ? 'auto' : '2rem 3.75rem'}
        height={isViewportLessThan766pxWide ? 'auto' : '85%'}
        width="auto"
      >
        <ModalBody
          maxW={overLoadModalSize}
          height="100%"
          padding={isViewportLessThan766pxWide ? '.3rem' : '1.5rem'}
        >
          {(!!title || !!helper) && (
            <Box p={4}>
              {title && (
                <Heading as="h3" size="md" pt={2}>
                  {title}
                </Heading>
              )}
              {helper && (
                <Text as="h4" size="sm" mt={4}>
                  {helper}
                </Text>
              )}
            </Box>
          )}
          <Flex flex={1} height="100%">
            <ReactPlayer
              url={url || null}
              width="100%"
              height="100%"
              controls
              playing
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

PreviewModal.propTypes = {
  title: PropTypes.node,
  helper: PropTypes.node,
  url: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

PreviewModal.defaultProps = {
  title: '',
  helper: '',
  url: '',
  isOpen: false,
  onClose: () => {},
};
