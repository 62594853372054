/* eslint-disable max-len */
import React from 'react';

import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const Logo = ({ isFull, ...otherProps }) => (
  <Box {...otherProps}>
    {isFull ? (
      <img src="/resapi-full.svg" alt="résa'pi" />
    ) : (
      <img src="/resapi-responsive.svg" alt="résa'pi" />
    )}
  </Box>
);

Logo.propTypes = {
  isFull: PropTypes.bool,
};

Logo.defaultProps = {
  isFull: false,
};
