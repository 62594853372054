import { useEffect, useState } from 'react';

import axios from 'axios';
import { useQueryCache } from 'react-query';

import { useUserContext } from '@/context/user-context';
import { useActivatedAccount } from '@/services/activation';
import { useNewCGU } from '@/services/cgu';
import { API_URL, USER_TOKEN_STORAGE_KEY } from '@/services/config';

axios.interceptors.request.use(
  (config = {}) => {
    const token = localStorage.getItem(USER_TOKEN_STORAGE_KEY);
    const authHeaders = token
      ? { Authorization: `Bearer ${JSON.parse(token)}` }
      : {};

    return {
      baseURL: API_URL,
      ...config,
      headers: {
        ...authHeaders,
        ...(config.headers || {}),
      },
    };
  },
  (error) => Promise.reject(error)
);
let didMount = false;

export const useNotActiveAccount = ({ setIsModalValidateAccount }) => {
  const { user: currentUser } = useUserContext();

  useEffect(() => {
    if (currentUser?.active === 0) {
      setIsModalValidateAccount(true);
    }
  }, [currentUser, setIsModalValidateAccount]);
};

export const useNotValidateCGU = ({ setIsModalCGU }) => {
  const { data: cgu } = useNewCGU();

  useEffect(() => {
    if (cgu === true) {
      setIsModalCGU(true);
    }
  }, [cgu, setIsModalCGU]);
};

export const useIsActivatedAccount = () => {
  const { user: currentUser, updateUser } = useUserContext();
  const { data: activation } = useActivatedAccount();
  const [activationChecked, setActivationChecked] = useState(false);

  useEffect(() => {
    if (!activationChecked && activation?.active) {
      const updatedUser = { ...currentUser, active: 1 };
      updateUser(updatedUser);
      setActivationChecked(true);
    }
  }, [currentUser, updateUser, activation, activationChecked]);
};

export const useNotValidateAccountDitection = () => {
  const { updateToken, updateUser } = useUserContext();
  const queryCache = useQueryCache();

  if (didMount === false) {
    didMount = true;
    axios.interceptors.response.use(
      (response) => response?.data,
      (error) => {
        const { status } = error?.response || {};
        if (status === 401 && error?.config?.url !== '/change_password') {
          updateToken(null);
          updateUser(null);
          queryCache.clear();
        }

        return Promise.reject(error);
      }
    );
  }
};
