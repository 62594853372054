import { useRef } from 'react';

import axios from 'axios';
import { useQuery } from 'react-query';

import { useUserContext } from '@/context/user-context';

export const useActivatedAccount = ({ params, ...config } = {}) => {
  const alreadyDoGet = useRef(false);
  const { user: currentUser } = useUserContext();
  return useQuery(
    ['activation', params],
    () => {
      if (currentUser !== null) {
        // évite une rêquete si non connecté
        if (!alreadyDoGet.current) {
          // Vérifier si la requête a déjà été effectuée
          alreadyDoGet.current = true;
          return axios.get('/activation', { params });
        }
      }
      return false;
    },
    config
  );
};
