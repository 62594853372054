import React from 'react';

import { Box, Button, Flex, Heading, Stack, useToast } from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { isEmail, isNotEmptyString } from '@formiz/validations';

import { FieldInput, Footer } from '@/components';
import { useUserContext } from '@/context/user-context';
import { Page, AppHeader } from '@/layout';
import { useUpdateAccount } from '@/services/login';
import {
  formatInternationalPhoneNumber,
  isValidFrenchPhoneNumber,
} from '@/utils/formCustomValidations';

export const Profile = () => {
  const form = useForm();
  const { user, updateUser } = useUserContext();
  const toast = useToast();
  const [updateAccount, { isLoading }] = useUpdateAccount({
    onError: ({ response }) => {
      if (response?.status === 503) {
        toast({
          title:
            'Trop de tentatives de modification, veuillez réessayer dans quelques minutes',
          status: 'error',
        });
      } else {
        toast({
          title:
            'Une erreur est survenue lors de la modification de vos informations',
          status: 'error',
        });
      }
    },
    onSuccess: () => {
      toast({
        title: 'Vos informations ont été modifiées avec succès',
        status: 'success',
      });
    },
  });

  const handleValidSubmit = (values) => {
    updateUser(form.values);
    updateAccount(values);
  };

  return (
    <Page px={6} pb={4} pt={16}>
      <AppHeader title="résa'pi" />
      <Formiz
        connect={form}
        initialValues={{
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          phoneNumber: user?.phoneNumber,
          location: user?.location,
        }}
        onValidSubmit={handleValidSubmit}
      >
        <form noValidate onSubmit={form.submit}>
          <Flex direction="column" align="center" m="auto" maxW="400px">
            <Heading as="h2" size="lg" mb={10}>
              Modifier mes informations
            </Heading>
            <Box w="100%">
              <FieldInput
                name="email"
                type="email"
                placeholder="Votre email"
                label={
                  <span>
                    Email<span style={{ color: 'red' }}> *</span>
                  </span>
                }
                isDisabled
                mt={10}
                validations={[
                  {
                    rule: isEmail(),
                    message: "L'adresse email n'est pas valide",
                  },
                ]}
              />
              <FieldInput
                name="firstName"
                label={
                  <span>
                    Prénom<span style={{ color: 'red' }}> *</span>
                  </span>
                }
                placeholder="Votre prénom"
                required="Le prénom est requis"
                mt={10}
                validations={[
                  {
                    rule: isNotEmptyString(),
                    message: 'Le prénom est requis',
                  },
                ]}
              />
              <FieldInput
                name="lastName"
                label={
                  <span>
                    Nom<span style={{ color: 'red' }}> *</span>
                  </span>
                }
                placeholder="Votre nom"
                required="Le nom est requis"
                mt={10}
                validations={[
                  {
                    rule: isNotEmptyString(),
                    message: 'Le nom est requis',
                  },
                ]}
              />
              <FieldInput
                name="phoneNumber"
                label="Téléphone"
                placeholder="Votre numéro de téléphone"
                formatValue={formatInternationalPhoneNumber}
                mt={10}
                validations={[
                  {
                    rule: isValidFrenchPhoneNumber(),
                    message: "Votre numéro de téléphone n'est pas valide",
                  },
                ]}
              />

              <Box marginTop={4}>
                <span style={{ color: 'red' }}> *</span> Mentions obligatoires
              </Box>

              <Stack spacing={4} d={{ base: 'none', md: 'flex' }} mt={6}>
                <Button
                  type="submit"
                  colorScheme="brandSecondary"
                  isDisabled={isLoading}
                >
                  Modifier
                </Button>
              </Stack>
            </Box>
          </Flex>
          <Footer
            as={Stack}
            spacing={4}
            d={{ base: 'flex', md: 'none' }}
            alignItems="center"
          >
            <Button
              type="submit"
              w="70%"
              colorScheme="brandSecondary"
              isDisabled={isLoading}
            >
              Modifier
            </Button>
          </Footer>
        </form>
      </Formiz>
    </Page>
  );
};
