import React from 'react';

import {
  Box,
  Flex,
  Text,
  useToast,
  Button,
  useDisclosure,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
  ModalFooter,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';

import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
  Icon,
} from '@/components';
import {
  BOOKING_REQUEST,
  BOOKING_REQUEST_SUBJECTS,
} from '@/constants/bookingRequest';
import { TIME_FORMAT } from '@/constants/date';
import { APPOINTMENT_CARD_ICONS } from '@/constants/meeting';
import { useCancelInvoiceRequest } from '@/services/invoiceRequest';
import { getAppointmentSubjectTextContent } from '@/services/utils';
import { formatAddress } from '@/utils/textUtils';

export const InvoiceRequestInformation = ({
  invoiceRequest,
  ...otherProps
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isWide = useBreakpointValue({ base: false, md: true });
  const modalSize = useBreakpointValue({
    base: 'xs',
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'md',
  });
  const history = useHistory();
  const toast = useToast();
  const queryCache = useQueryCache();
  const [mutate, { isLoading }] = useCancelInvoiceRequest({
    onError: () => {
      toast({
        title:
          "Une erreur est survenue lors de l'annulation de la demande de devis",
        status: 'error',
      });
    },
    onSuccess: () => {
      queryCache.invalidateQueries('invoiceRequests');
      history.push('/bookings');
      toast({
        title: 'La demande de devis a été annulée avec succès',
        status: 'success',
      });
    },
  });
  const {
    subject: type,
    appointmentProposalDates,
    appointmentAddress,
  } = invoiceRequest;
  const subject = invoiceRequest[type];
  const address = formatAddress(appointmentAddress);

  const dates = (appointmentProposalDates || []).map((proposalDate) => {
    const { startDate, endDate } = proposalDate;
    const startDateObj =
      startDate && dayjs(startDate).isValid() ? dayjs(startDate) : null;
    const endDateObj =
      endDate && dayjs(endDate).isValid() ? dayjs(endDate) : null;
    const dateToDisplay = startDateObj
      ? startDateObj.format('DD MMMM YYYY')
      : 'N/A';
    const startTimeToDisplay = startDateObj
      ? startDateObj.format(TIME_FORMAT)
      : 'N/A';
    const endTimeToDisplay = endDateObj
      ? endDateObj.format(TIME_FORMAT)
      : 'N/A';

    return {
      dateToDisplay,
      startTimeToDisplay,
      endTimeToDisplay,
    };
  });

  const appointmentDate = (appointmentProposalDates || []).map(
    (proposalDate) => {
      const { startDate } = proposalDate;
      const startDateObj =
        startDate && dayjs(startDate).isValid() ? dayjs(startDate) : null;

      const dateToDisplay = startDateObj
        ? startDateObj.format('YYYY-MM-D')
        : 'N/A';
      const startTimeToDisplay = startDateObj
        ? startDateObj.format('HH:mm')
        : 'N/A';

      const dateAndTime = new Date(`${dateToDisplay}T${startTimeToDisplay}`);
      const timestampDateAndTime = dateAndTime.getTime();

      return {
        timestampDateAndTime,
      };
    }
  );

  const subjectName =
    BOOKING_REQUEST_SUBJECTS[BOOKING_REQUEST[type]]?.name || '';

  const iconProps =
    APPOINTMENT_CARD_ICONS[type] || APPOINTMENT_CARD_ICONS.DEFAULT;

  const invoiceRequestSubjectContent = getAppointmentSubjectTextContent(
    subject
  );

  const currentTime = dayjs().$d;
  const timestampNow = currentTime?.getTime();
  const timestampAppointment = appointmentDate[0]?.timestampDateAndTime;

  const handleCancelInvoiceRequest = () => {
    mutate({ id: invoiceRequest.id });
    onClose();
  };

  return (
    <>
      <Card w="100%" borderWidth="0" flexDir="column" {...otherProps}>
        <CardContent
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'flex-start', md: 'space-between' }}
        >
          <Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              w="3rem"
              h="3rem"
              overflow="hidden"
              bg="brandSecondary.100"
              borderRadius="spot.variant1"
            >
              <Icon mt="1" color="brandSecondary.700" {...iconProps} />
            </Flex>
            <Flex flexDir="column">
              {dates?.map((ele, ind) => (
                <Box key={ind} ml="3" mb={2}>
                  <CardTitle
                    as="h3"
                    {...(isWide ? { fontSize: '1.2rem' } : { size: 'lg' })}
                  >
                    {ele?.dateToDisplay}
                  </CardTitle>
                  <CardTitle
                    as="h3"
                    {...(isWide ? { fontSize: '1rem' } : { size: 'md' })}
                  >
                    {`${ele?.startTimeToDisplay} à ${ele?.endTimeToDisplay}`}
                  </CardTitle>
                </Box>
              ))}
            </Flex>
          </Flex>
          <CardDescription
            h="auto"
            mt={{ base: 3, md: 0 }}
            w={{ base: 'auto', md: '27rem' }}
            {...(isWide
              ? {
                  display: 'flex',
                  flexDir: 'column',
                }
              : {})}
          >
            <Text fontSize="md" mt={{ base: 4, md: 0 }}>
              <Icon icon={FaMapMarkerAlt} color="brandSecondary.700" mr="2" />
              {address}
            </Text>
            <Text fontSize="md" mt={4}>
              <Text as="span">Rendez-vous:</Text>
              <Text as="span" fontWeight="bold">
                {`${subjectName ? ` ${subjectName}` : ''}${
                  invoiceRequestSubjectContent[type] ? ' -' : ''
                }`}
                <Text as="span" fontWeight="bold">
                  {` ${invoiceRequestSubjectContent[type] || ''}`}
                </Text>
              </Text>
            </Text>
            {!isWide && (
              <Box m={1}>
                <Button colorScheme="danger" mt={6} onClick={onOpen}>
                  Annuler la demande
                </Button>
              </Box>
            )}
          </CardDescription>
        </CardContent>
        {isWide && (
          <Box mt={2}>
            <Button
              colorScheme="danger"
              onClick={onOpen}
              float={{ base: 'none', md: 'right' }}
              {...(timestampNow >= timestampAppointment && {
                isDisabled: true,
              })}
            >
              Annuler la demande
            </Button>
          </Box>
        )}
      </Card>
      <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" size="lg" pt={2}>
              Êtes-vous sûr de vouloir annuler votre demande de devis ?
            </Heading>
            <Heading as="h4" size="sm" mt={1}>
              Toutes les informations de votre demande seront perdues...
            </Heading>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="brand" size="sm" mr={3} onClick={onClose}>
              Abandonner
            </Button>
            <Button
              size="sm"
              colorScheme="danger"
              isDisabled={isLoading}
              onClick={handleCancelInvoiceRequest}
            >
              Annuler la demande
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

InvoiceRequestInformation.propTypes = {
  invoiceRequest: PropTypes.shape({
    id: PropTypes.string,
    subject: PropTypes.string,
    appointmentProposalDates: PropTypes.arrayOf(PropTypes.object),
    appointmentAddress: PropTypes.shape({
      streetAddress: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
    }),
  }),
};

InvoiceRequestInformation.defaultProps = {
  invoiceRequest: {
    id: '',
    subject: '',
    appointmentProposalDates: null,
    appointmentAddress: {
      streetAddress: '',
      zipCode: '',
      city: '',
    },
  },
};
