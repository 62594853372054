import React from 'react';

import { useBreakpointValue } from '@chakra-ui/media-query';
import { Flex, Spacer, Text } from '@chakra-ui/react';

import { FieldInput, FieldSelect } from '@/components';
import { RECURRENT_DATE_UNIT } from '@/constants/date';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';

export const AppointmentRecurringDateInformation = () => {
  const flexDirection = useBreakpointValue({ base: 'column', sm: 'row' });
  return (
    <>
      <Flex>
        <AppointmentHeading
          title="Date du rendez-vous"
          helper="Choisissez la récurrence et le nombre des rendez-vous"
        />
        <Spacer />
      </Flex>
      <Flex w="100%" mt={10} flexDirection={flexDirection}>
        <Text mr="4">Répéter tou(te)s les</Text>
        <Flex flex={1}>
          <FieldInput
            h="auto"
            maxW={20}
            mr="4"
            mb={0}
            defaultValue={1}
            inputProps={{
              max: 20,
              min: 1,
            }}
            name="appointment.datetime.recurringCount"
            type="number"
          />
          <FieldSelect
            maxW={150}
            h="auto"
            defaultValue={{ value: 'semaine', label: 'Semaine(s)' }}
            name="appointment.datetime.recurringTimeUnit"
            options={RECURRENT_DATE_UNIT}
          />
        </Flex>
      </Flex>
      <Flex w="100%" mt={10} flexDirection={flexDirection}>
        <Text mr="4">Nombre de rendez-vous que vous allez prendre</Text>
        <Flex flex={1}>
          <FieldInput
            h="auto"
            maxW={20}
            mr="4"
            mb={0}
            defaultValue={2}
            inputProps={{
              max: 10,
              min: 2,
            }}
            name="appointment.datetime.meetingCount"
            type="number"
          />
        </Flex>
      </Flex>
    </>
  );
};
