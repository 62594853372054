import React from 'react';

import { Flex, Spacer, Stack } from '@chakra-ui/react';

import { FieldRadioGroup } from '@/components';
import { BOOKING_REQUEST_DATE_TIME } from '@/constants/bookingRequest';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';
import { RadioItem } from '@/pages/BookingRequest/_partials/RadioItem';

export const AppointmentDateTimeType = () => (
  <>
    <Flex>
      <AppointmentHeading
        title="Date du rendez-vous"
        helper="Choisissez le type de date en fonction des caractéristiques du rendez-vous."
      />
      <Spacer />
    </Flex>
    <FieldRadioGroup
      my="4"
      name="appointment.datetime.appointmentDateTimeType"
      required="Le type de date et d'heure de rendez-vous est requis"
    >
      <Stack>
        <RadioItem {...BOOKING_REQUEST_DATE_TIME.FIXED_DATE} />
        <RadioItem {...BOOKING_REQUEST_DATE_TIME.FREE_DATE} />
        <RadioItem {...BOOKING_REQUEST_DATE_TIME.MULTI_DATES} />
        <RadioItem {...BOOKING_REQUEST_DATE_TIME.RECURRING_DATE} />
      </Stack>
    </FieldRadioGroup>
  </>
);
